<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/admin/alunos?secretaria'
                "
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/alunos?secretaria')"
              >Gerenciamento de alunos</a>
            </li>    
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/aluno/' + fastPessoa.id_pessoa
                "
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + fastPessoa.id_pessoa)"
              >Aluno {{ fastPessoa.nome_razao + ' ' + fastPessoa.sobrenome_fantasia }}</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Matricular aluno</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Matricular
                  <span>aluno</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- Turma -->
              <div class="row">
                <div class="col-12 mt-4">
                  <form-wizard
                    ref="wizard"
                    :start-index="currentStep"
                    :finish-button-text="'Finalizar'"
                    :back-button-text="'Voltar'"
                    :next-button-text="'Avançar'"
                    :title="fastPessoa.nome_razao + ' ' + fastPessoa.sobrenome_fantasia"
                    :subtitle="fastPessoa.email"
                    :step-size="'md'"
                    :validate-on-back="false"
                    shape="circle"
                    color="#5da3cc"
                    error-color="#e74c3c"
                    @on-complete="onComplete"
                    @on-validate="handleValidation"
                    @on-error="handleErrorMessage"
                  >
                    <wizard-step 
                      slot="step"
                      slot-scope="props"
                      :tab="props.tab"
                      :transition="props.transition"
                      :index="props.index"
                    />

                    <tab-content
                      title="Curso"
                      :before-change="validaPrimeiroPasso"
                      icon
                    >
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">
                                1 - Cursos e turmas disponíveis
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row mt-2 mb-2">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h4 class="text-secondary">
                                Escolha o curso
                              </h4>                          
                              <select
                                v-if="fastSecretariaCursosTotal.length"
                                v-model="fastSteps.step1.curso.id_secretaria_curso"
                                class="form-control"
                                @change="getSecretariaTurmas()"
                              >
                                <option
                                  value="0"
                                  selected
                                >
                                  -- Selecione um curso --
                                </option>
                                <option
                                  v-for="curso in fastSecretariaCursosTotal"
                                  :key="curso.id_secretaria_curso"
                                  :value="curso.id_secretaria_curso"
                                >
                                  {{ curso.nome_curso }}
                                </option>
                              </select>  
                              <select
                                v-else
                                class="form-control"
                              >
                                <option value="">
                                  -- Nenhum curso encontrado --
                                </option>
                              </select>    
                            </div>
                            <div
                              v-if="fastSteps.step1.curso.id_secretaria_curso"
                              class="col-sm-12 col-md-12 col-lg-6"
                            >
                              <h4 class="text-secondary">
                                Turmas disponíveis
                              </h4>      
                              <select
                                v-if="fastSteps.step1.turma.loading"
                                class="form-control"
                              >
                                <option value="">
                                  -- Carregando turmas --
                                </option>
                              </select>                     
                              <select
                                v-else-if="fastSecretariaTurmasTotal.length"
                                v-model="fastSteps.step1.turma.id_turma"
                                class="form-control"
                              >
                                <option
                                  value="0"
                                  selected
                                >
                                  -- Selecione uma turma --
                                </option>
                                <option
                                  v-for="turma in fastSecretariaTurmasTotal"
                                  :key="turma.id_turma"
                                  :value="turma.id_turma"
                                >
                                  {{ turma.sigla_turma }}
                                  <span v-if="turma.turno == 1"> - Manhã</span>
                                  <span v-else-if="turma.turno == 2"> - Tarde</span>
                                  <span v-else-if="turma.turno == 3"> - Noite</span>
                                  <span v-else> - Sem turno</span>
                                </option>
                              </select>  
                              <select
                                v-else
                                class="form-control"
                              >
                                <option value="">
                                  -- Nenhuma turma disponível --
                                </option>
                              </select>    
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div
                              v-if="fastSteps.step1.curso.id_secretaria_curso"
                              class="col-sm-12 col-md-12 col-lg-12"
                            >
                              <div v-if="fastSteps.step1.curso.possui_aproveitamento">
                                <span class="text-info">Esse curso possui aproveitamento</span>
                              </div>
                              <div v-else>
                                <span class="text-danger">Esse curso não possui opção de aproveitamento</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab-content>
                    <tab-content
                      title="Planos"
                      :before-change="validaSegundoPasso"
                      icon
                    >
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">
                                2 - Opções de plano
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row mt-2 mb-2">
                            <div class="col-sm-12 col-md-12 col-lg-4">
                              <div class="list-group">
                                <div
                                  class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                                  :class="[fastSteps.step2.plano == 'curso' ? 'active' : '']"
                                  @click="fastSteps.step2.plano = 'curso'"
                                >
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h5>Curso completo</h5>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <small>Valor: R$ {{ formataPreco(fastSteps.step1.curso.preco) }}</small>
                                    </div>                                    
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="fastSteps.step1.curso.venda_modulo"
                                class="list-group"
                              >
                                <div
                                  class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                                  :class="[fastSteps.step2.plano == 'modulo' ? 'active' : '']"
                                  @click="fastSteps.step2.plano = 'modulo'"
                                >
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h5>Módulos</h5>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <small>Valor: variável</small>
                                    </div>                                    
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="fastSteps.step1.curso.venda_unidade_curricular"
                                class="list-group"
                              >
                                <div
                                  class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                                  :class="[fastSteps.step2.plano == 'unidade' ? 'active' : '']"
                                  @click="fastSteps.step2.plano = 'unidade'"
                                >                                
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h5>Unidades curriculares</h5>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <small>Valor: variável</small>
                                    </div>                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-8">
                              <div class="list-group">
                                <div
                                  v-if="fastSteps.step2.plano == 'curso'"
                                  class="flex-column align-items-start"
                                >                                
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                      <h5 class="text-info">
                                        Plano escolhido: Curso completo
                                      </h5> 
                                      <p>O aluno será matriculado na turma escolhida e terá acesso a todos os módulos do curso.</p>
                                      <h6>Módulos:</h6>
                                      <div v-if="fastSteps.step2.modulo.loading">
                                        <h6>Carregando...</h6>
                                      </div> 
                                      <ul v-else-if="fastSecretariaModulosTotal.length">
                                        <li
                                          v-for="modulo in fastSecretariaModulosTotal"
                                          :key="modulo.id_programa_turma"
                                          :value="modulo.id_programa_turma"
                                          style="list-style-position: inside"
                                        >
                                          {{ modulo.sigla_programa }}
                                          <span v-if="modulo.data_inicio">{{
                                            " - " +
                                              modulo.data_inicio.split("-")[2] +
                                              "/" +
                                              modulo.data_inicio.split("-")[1] +
                                              "/" +
                                              modulo.data_inicio.split("-")[0]
                                          }}</span>
                                          <span v-else> - Sem data</span> 
                                          <span v-if="modulo.creditos">{{
                                            " - " +
                                              modulo.creditos + " créditos"
                                          }}</span>
                                        </li>
                                      </ul>
                                      <div v-else>
                                        <h6>Nenhum módulo encontrado</h6>
                                      </div>
                                    </div>
                                  </div>  
                                </div>
                                <div
                                  v-if="fastSteps.step2.plano == 'modulo'"
                                  class="flex-column align-items-start"
                                > 
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                      <h5 class="text-info">
                                        Plano escolhido: Módulo do curso
                                      </h5> 
                                      <p>O aluno pode ser matriculado em um ou mais módulos.</p>     
                                      <div class="form-check d-flex align-items-center">
                                        <input
                                          id="checkRequisitosObrigatorios"
                                          v-model="fastSteps.step2.modulo.requisitos_obrigatorios"
                                          type="checkbox"
                                          class="form-check-input"
                                        >
                                        <label
                                          class="form-check-label text-danger"
                                          for="checkRequisitosObrigatorios"
                                        >
                                          Requisitos obrigatórios                                        
                                        </label>
                                      </div>                                 
                                    </div>
                                  </div>                                
                                  <div class="row">                                    
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                      <div
                                        v-if="fastSteps.step2.modulo.loading"
                                        class="list-group-item list-group-item-action flex-column align-items-start"
                                      >                                
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                            <span class="text-info">Carregando...</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div v-else-if="fastSecretariaModulosTotal.length">
                                        <div
                                          v-for="modulo in fastSecretariaModulosTotal"
                                          :key="modulo.id_programa_turma"
                                        >
                                          <div
                                            class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                                            :class="[fastSteps.step2.modulo.lista.includes(modulo.id_secretaria_curso_programa) ? 'active' : '']"
                                            @click="selecionaModulo(modulo)"
                                          >                                
                                            <div class="row">
                                              <div class="col-sm-12 col-md-12 col-lg-12">
                                                <div>
                                                  <b>{{ modulo.sigla_programa }}</b>
                                                  <b v-if="modulo.data_inicio">{{
                                                    " - " +
                                                      modulo.data_inicio.split("-")[2] +
                                                      "/" +
                                                      modulo.data_inicio.split("-")[1] +
                                                      "/" +
                                                      modulo.data_inicio.split("-")[0]
                                                  }}</b>
                                                  <b v-else> - Sem data definida</b>                                                  
                                                  <p
                                                    v-if="modulo.creditos"
                                                    class="mb-0"
                                                  >
                                                    <small>{{ "Créditos: " + modulo.creditos }}</small>
                                                  </p>
                                                  <p
                                                    v-if="modulo.preco"
                                                    class="mb-0"
                                                  >
                                                    <small>{{ "Valor: R$ " + formataPreco(modulo.preco) }}</small>
                                                  </p>
                                                  <p
                                                    v-else
                                                    class="mb-0"
                                                  >
                                                    <small>Sem valor definido</small>
                                                  </p>
                                                </div>
                                                <p
                                                  v-if="modulo.id_pai && fastSteps.step2.modulo.requisitos_obrigatorios"
                                                  class="text-danger"
                                                >
                                                  <small>Pré-requisito:
                                                    <span
                                                      v-for="modulo2 in fastSecretariaModulosTotal"
                                                      :key="modulo2.id_programa_turma"
                                                    >
                                                      <b v-if="modulo.id_pai == modulo2.id_secretaria_curso_programa">{{ modulo2.sigla_programa }}</b>
                                                    </span>
                                                  </small>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>    
                                        <div class="text-center">
                                          <p class="text-info">
                                            Valor total: R$ {{ formataPreco(calculaPrecoTotal(fastSteps.step2.modulo.lista, 'modulo')) }}
                                          </p>  
                                        </div>                                    
                                      </div>                                      
                                      <div
                                        v-else
                                        class="list-group-item list-group-item-action flex-column align-items-start"
                                      >                                
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                            <span class="info">Nenhum módulo encontrado</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>  
                                </div>
                                <div
                                  v-else-if="fastSteps.step2.plano == 'unidade'"
                                  class="flex-column align-items-start"
                                > 
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                      <h5 class="text-info">
                                        Plano escolhido: Unidade curricular
                                      </h5> 
                                      <p>O aluno pode ser matriculado em uma ou mais unidades curriculares.</p>    
                                      <div class="form-check d-flex align-items-center">
                                        <input
                                          id="checkRequisitosObrigatorios"
                                          v-model="fastSteps.step2.unidade.requisitos_obrigatorios"
                                          type="checkbox"
                                          class="form-check-input"
                                        >
                                        <label
                                          class="form-check-label text-danger"
                                          for="checkRequisitosObrigatorios"
                                        >
                                          Requisitos obrigatórios                                        
                                        </label>
                                      </div>                                  
                                    </div>
                                  </div>                               
                                  <div class="row">                                    
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                      <div
                                        v-if="fastSteps.step2.modulo.loading"
                                        class="list-group-item list-group-item-action flex-column align-items-start"
                                      >                                
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                            <span class="text-info">Carregando...</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="fastSecretariaModulosTotal.length"
                                        class="mt-4"
                                      >
                                        <div
                                          v-for="modulo in fastSecretariaModulosTotal"
                                          :key="modulo.id_programa_turma"
                                          class="mb-4"
                                        >
                                          <div class="mb-2">                                
                                            <div class="row">
                                              <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                                <h5>
                                                  {{ modulo.sigla_programa }} - Unidades curriculares
                                                </h5>
                                                <p
                                                  v-if="modulo.id_pai && fastSteps.step2.modulo.requisitos_obrigatorios"
                                                  class="text-danger mb-0"
                                                >
                                                  Pré-requisito:
                                                  <span
                                                    v-for="modulo2 in fastSecretariaModulosTotal"
                                                    :key="modulo2.id_programa_turma"
                                                  >
                                                    <b v-if="modulo.id_pai == modulo2.id_secretaria_curso_programa">{{ modulo2.sigla_programa }}</b>
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div
                                                v-if="fastSteps.step2.unidade.loading"
                                                class="col-12"
                                              >
                                                <div class="row">
                                                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                                    <span class="text-info">Carregando...</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                v-if="modulo.unidades_curriculares.length"
                                                class="col-12"
                                              >
                                                <div
                                                  v-for="(unidade, index) in modulo.unidades_curriculares"
                                                  :key="unidade.id_secretaria_curso_programa_unidade"
                                                >
                                                  <div
                                                    class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                                                    :class="[fastSteps.step2.unidade.lista.includes(unidade.id_secretaria_curso_programa_unidade) ? 'active' : '']"
                                                    @click="selecionaUnidade(unidade)"
                                                  >                                
                                                    <div class="row">
                                                      <div class="col-sm-12 col-md-12 col-lg-12">
                                                        <div>
                                                          <b>{{ "UC " + (index + 1) + " - " + unidade.nome_curso }}</b>
                                                          <b v-if="unidade.data_inicio">{{
                                                            " - " +
                                                              unidade.data_inicio.split("-")[2] +
                                                              "/" +
                                                              unidade.data_inicio.split("-")[1] +
                                                              "/" +
                                                              unidade.data_inicio.split("-")[0]
                                                          }}</b>
                                                          <b v-else> - Sem data definida</b>

                                                          <p
                                                            v-if="unidade.creditos"
                                                            class="mb-0"
                                                          >
                                                            <small>{{ "Créditos: " + unidade.creditos }}</small>
                                                          </p>
                                                          <p
                                                            v-if="unidade.preco"
                                                            class="mb-0"
                                                          >
                                                            <small>{{ "Valor: R$ " + formataPreco(unidade.preco) }}</small>
                                                          </p>
                                                          <p
                                                            v-else
                                                            class="mb-0"
                                                          >
                                                            <small>Sem valor definido</small>
                                                          </p>
                                                        </div>
                                                        <p
                                                          v-if="unidade.id_pai && fastSteps.step2.unidade.requisitos_obrigatorios"
                                                          class="text-danger mb-0"
                                                        >
                                                          <small>Pré-requisito:
                                                            <span
                                                              v-for="unidade2 in fastSecretariaUnidadesTotal"
                                                              :key="unidade2.id_secretaria_curso_programa_unidade"
                                                            >
                                                              <b v-if="unidade.id_pai == unidade2.id_secretaria_curso_programa_unidade">{{ unidade2.nome_curso }}</b>
                                                            </span></small>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                v-else
                                                class="col-12"
                                              >
                                                <div class="row">
                                                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                                    <div class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2">
                                                      <span class="info">Nenhuma unidade curricular encontrada</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>    
                                        <div class="text-center">
                                          <p class="text-info">
                                            Valor total: R$ {{ formataPreco(calculaPrecoTotal(fastSteps.step2.unidade.lista, 'unidade')) }}
                                          </p>  
                                        </div>                                    
                                      </div>                                      
                                      <div
                                        v-else
                                        class="list-group-item list-group-item-action flex-column align-items-start"
                                      >                                
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                            <span class="info">Nenhum módulo encontrado</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                                </div>                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab-content>
                    <tab-content
                      title="Pagamento"
                      :before-change="validaTerceiroPasso"
                      icon
                    >
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">
                                3 - Opções de pagamento
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div v-if="!fastSteps.step3.pular_pagamento" class="row mt-2 mb-2">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h4 class="text-secondary">
                                Plano de pagamento
                              </h4>  
                              <select
                                v-if="fastSteps.step3.plano.loading"
                                class="form-control"
                              >
                                <option value="">
                                  Carregando planos...
                                </option>
                              </select>                        
                              <select
                                v-else-if="fastSecretariaPlanoPagamento.length"
                                v-model="fastSteps.step3.plano.id_plano_pagamento"
                                class="form-control"
                                @change="verificaConfiguracaoPagamento()"
                              >
                                <option value="0">
                                  -- Selecione um plano de pagamento --
                                </option>
                                <option
                                  v-for="plano in fastSecretariaPlanoPagamento"
                                  :key="plano.id_plano_pagamento"
                                  :value="plano.id_plano_pagamento"
                                >
                                  {{ plano.nome_plano }}
                                </option>
                              </select>  
                              <select
                                v-else
                                class="form-control"
                              >
                                <option value="">
                                  -- Nenhum plano de pagamento encontrado --
                                </option>
                              </select>    
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h4 class="text-secondary">
                                Tipo de pagamento
                              </h4>  
                              <select
                                v-if="fastSteps.step3.tipo.loading"
                                class="form-control"
                              >
                                <option value="">
                                  Carregando tipos...
                                </option>
                              </select>                        
                              <select
                                v-else-if="fastSecretariaTipoPagamento.length"
                                v-model="fastSteps.step3.tipo.id_tipo_pagamento"
                                class="form-control"
                                @change="verificaConfiguracaoPagamento()"
                              >
                                <option value="0">
                                  -- Selecione um tipo de pagamento --
                                </option>
                                <option
                                  v-for="tipo in fastSecretariaTipoPagamento"
                                  :key="tipo.id_tipo_pagamento"
                                  :value="tipo.id_tipo_pagamento"
                                >
                                  {{ tipo.nome_tipo_pagamento }}
                                  <span v-if="tipo.forma_pagamento"> - {{ formataFormaPagamento(tipo.forma_pagamento) }}</span>
                                </option>
                              </select>  
                              <select
                                v-else
                                class="form-control"
                              >
                                <option value="">
                                  -- Nenhum tipo de pagamento encontrado --
                                </option>
                              </select>    
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12">
                              <input
                                v-model="fastSteps.step3.pular_pagamento"
                                type="checkbox"
                                id="chkNaoGerarInvoice"
                              > <label for="chkNaoGerarInvoice">Gerar matrícula sem pagamento</label>
                            </div>
                          </div>
                          <div
                            v-if="!fastSteps.step3.pular_pagamento && fastSteps.step3.plano.id_plano_pagamento && fastSteps.step3.tipo.id_tipo_pagamento"
                            class="row mt-4 mb-2"
                          >
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <div class="row mt-2">
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h5>Detalhes do plano</h5>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                                      <p class="mb-0">
                                        <span>Nome do plano:</span> {{ fastSteps.step3.plano.nome_plano }}
                                      </p>
                                      <p class="mb-0">
                                        <span>Observações:</span> {{ fastSteps.step3.plano.obs }}
                                      </p>
                                      <p class="mb-0">
                                        <span>Dia/mês: </span> 
                                        <span v-if="fastSteps.step3.plano.dia_mes == 'D'"> Dia </span>
                                        <span v-else-if="fastSteps.step3.plano.dia_mes == 'M'"> Mês </span>
                                      </p>
                                      <p class="mb-0">
                                        <span>Periodicidade: </span> 
                                        <span v-if="fastSteps.step3.plano.periocidade_cada_unidade == 0">Indeterminado</span>
                                        <span v-else-if="fastSteps.step3.plano.dia_mes == 'D'">
                                          <span v-if="fastSteps.step3.plano.periocidade_cada_unidade == 1">Todo dia</span>
                                          <span v-else>A cada {{ fastSteps.step3.plano.periocidade_cada_unidade }} dias</span>
                                        </span>
                                        <span v-else-if="fastSteps.step3.plano.dia_mes == 'M'">                                          
                                          <span v-if="fastSteps.step3.plano.periocidade_cada_unidade == 1">Todo mês</span>
                                          <span v-else>A cada {{ fastSteps.step3.plano.periocidade_cada_unidade }} meses</span>
                                        </span>
                                      </p>
                                      <p class="mb-0">
                                        <span>Tempo determinado:</span> 
                                        <span v-if="fastSteps.step3.plano.tempo_determinado == 0">Indeterminado</span>
                                        <span v-else-if="fastSteps.step3.plano.dia_mes == 'D'">
                                          {{ fastSteps.step3.plano.tempo_determinado }} dias
                                        </span>
                                        <span v-else-if="fastSteps.step3.plano.dia_mes == 'M'">                                          
                                          {{ fastSteps.step3.plano.tempo_determinado }} meses
                                        </span>
                                      </p>
                                    </div>                                    
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h5>Descontos e acréscimos do plano</h5>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 mt-2">                                      
                                      <div class="row text-center">
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                          <small>Acréscimo (total)</small>
                                          <money
                                            v-model="fastSteps.step3.invoice.acrescimo"                                              
                                            class="form-control"
                                            @change.native="aplicaAjusteValor()"
                                          />
                                        </div>   
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                          <small>Desconto (total)</small>
                                          <money
                                            v-model="fastSteps.step3.invoice.desconto_total"
                                            class="form-control"
                                            @change.native="aplicaAjusteValor()"
                                          />
                                        </div>    
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                          <small>Taxa do boleto (parcela)</small>
                                          <money
                                            v-model="fastSteps.step3.invoice.taxa_boleto"
                                            class="form-control"
                                            @change.native="aplicaAjusteValor()"
                                          />
                                        </div>   
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                          <small>Taxa do cartão (parcela)</small>
                                          <money
                                            v-model="fastSteps.step3.invoice.taxa_cartao"
                                            class="form-control"
                                            @change.native="aplicaAjusteValor()"
                                          />
                                        </div>  
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                          <small>Outras taxas (parcela)</small>
                                          <money
                                            v-model="fastSteps.step3.invoice.outras_taxas"
                                            class="form-control"
                                            @change.native="aplicaAjusteValor()"
                                          />
                                        </div>       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h5>Valores do plano</h5>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 mt-2">                                      
                                      <p class="mb-0">
                                        <span>Subtotal: R$ {{ formataPreco(fastSteps.step3.invoice.subtotal) }}</span>
                                        <span
                                          v-if="fastSteps.step3.invoice.acrescimo > 0"
                                          class="text-danger"
                                        > + R$ {{ formataPreco(fastSteps.step3.invoice.acrescimo) }} (acréscimo)</span>
                                        <span
                                          v-if="fastSteps.step3.invoice.desconto_total > 0"
                                          class="text-success"
                                        > - R$ {{ formataPreco(fastSteps.step3.invoice.desconto_total) }} (desconto)</span>
                                        <span v-if="fastSteps.step3.plano.parcelas.length">
                                          <span v-if="fastSteps.step3.plano.parcelas[0].taxa_boleto || fastSteps.step3.plano.parcelas[0].taxa_cartao || fastSteps.step3.plano.parcelas[0].outras_taxas" class="text-danger"> + taxas das parcelas</span>
                                        </span>
                                      </p>
                                      <p>
                                        <span>Total: R$ {{ formataPreco(fastSteps.step3.invoice.total) }}</span>
                                      </p>
                                      <p
                                        v-if="fastSteps.step3.plano.parcelas.length"
                                        class="mb-0 d-none"
                                      >
                                        {{ fastSteps.step3.plano.parcelas.length }} x R$ {{ formataPreco(fastSteps.step3.plano.parcelas[0].valor_parcela) }}
                                        <span v-if="fastSteps.step3.plano.tempo_determinado == 0"> por tempo indeterminado</span>                                        
                                      </p>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                                      <button
                                        class="btn btn-sm btn-primary"
                                        @click="showModal('modalDefinirPrimeiraParcela')"
                                      >
                                        <small>Definir primeira parcela</small>
                                      </button>                                      
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <div
                                        v-if="fastSteps.step3.plano.parcelas.length"
                                        class="mt-2 list-group"
                                      >
                                        <div
                                          v-for="(parcela, index) in fastSteps.step3.plano.parcelas"
                                          :key="index"
                                          class="mb-2 text-secondary list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                                        >
                                          <b class="text-info">Parcela {{ index + 1 }} - {{ parcela.data_formatada }} - R$ {{ formataPreco(parcela.valor_parcela) }}
                                            <span
                                              v-if="fastSteps.step3.invoice.taxa_boleto > 0"
                                              class="text-danger"
                                            > + R$ {{ formataPreco(fastSteps.step3.invoice.taxa_boleto) }} (taxa boleto)</span>
                                            <span
                                              v-if="fastSteps.step3.invoice.taxa_cartao > 0"
                                              class="text-danger"
                                            > + R$ {{ formataPreco(fastSteps.step3.invoice.taxa_cartao) }} (taxa cartão)</span>
                                            <span
                                              v-if="fastSteps.step3.invoice.outras_taxas > 0"
                                              class="text-danger"
                                            > + R$ {{ formataPreco(fastSteps.step3.invoice.outras_taxas) }} (outras taxas)</span>
                                            <span v-if="fastSteps.step3.invoice.taxa_boleto > 0 || fastSteps.step3.invoice.taxa_cartao > 0 || fastSteps.step3.invoice.outras_taxas > 0"> = R$ {{ formataPreco(parseFloat(parcela.valor_parcela) + parseFloat(fastSteps.step3.invoice.taxa_boleto ? fastSteps.step3.invoice.taxa_boleto : 0) + parseFloat(fastSteps.step3.invoice.taxa_cartao ? fastSteps.step3.invoice.taxa_cartao : 0) + parseFloat(fastSteps.step3.invoice.outras_taxas ? fastSteps.step3.invoice.outras_taxas : 0)) }}</span>
                                          </b>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>                              
                              <div class="row mt-2">
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h5>Detalhes da matrícula</h5>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                                      <label>RA</label>
                                      <input
                                        v-model="fastSteps.step3.matricula.RA"
                                        type="text"
                                        class="form-control"
                                        maxlength="30"
                                      >
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                      <label>Data de vencimento do boleto</label>
                                      <input
                                        v-model="fastSteps.step3.matricula.vencimento_matricula"
                                        type="date"
                                        class="form-control"
                                      >
                                    </div>   
                                    <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                      <div class="fd-app-curso-aulas mt-0">
                                        <div class="fd-app-curso-aulas-list mt-0">
                                          <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                            <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                              <div class="col-12 text-center mb-1">
                                                <span>Ativo</span>
                                              </div>
                                              <div class="col-12 text-center">
                                                <label class="switch">
                                                  <input
                                                    v-model="fastSteps.step3.matricula.ativo"
                                                    type="checkbox"
                                                  >
                                                  <span class="slider round" />
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-if="fastSteps.step3.matricula.ativo"
                                      class="col-sm-12 col-md-12 col-lg-6 mb-2"
                                    >
                                      <span class="text-danger">Obs: Ao ativar a matrícula o aluno terá acesso imediato ao curso!</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h5>Descontos e acréscimos da matrícula</h5>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                                      <label>Valor da matrícula</label>
                                      <money
                                        v-model="fastSteps.step3.matricula.valor_matricula"
                                        class="form-control"
                                      />
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                                      <label>Valor do desconto</label>
                                      <money  
                                        v-if="fastSteps.step3.matricula.valor_matricula && fastSteps.step3.matricula.valor_matricula > 0"                                      
                                        v-model="fastSteps.step3.matricula.desconto_matricula"
                                        class="form-control"
                                      />
                                      <input v-else type="text" class="form-control" value="0" readonly>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                                      <label>Outros valores</label>
                                      <money
                                        v-if="fastSteps.step3.matricula.valor_matricula && fastSteps.step3.matricula.valor_matricula > 0"
                                        v-model="fastSteps.step3.matricula.outros_valores"
                                        class="form-control"
                                      />
                                      <input v-else type="text" class="form-control" value="0" readonly>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <h5>Valores da matrícula</h5>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 mt-2">   
                                      <p class="mb-0">
                                        Subtotal: <span v-if="fastSteps.step3.matricula.valor_matricula > 0">
                                          R$ {{ formataPreco(fastSteps.step3.matricula.valor_matricula) }}
                                          <span
                                            v-if="fastSteps.step3.matricula.outros_valores > 0"
                                            class="text-danger"
                                          > + R$ {{ formataPreco(fastSteps.step3.matricula.outros_valores) }}</span>
                                          <span
                                            v-if="fastSteps.step3.matricula.desconto_matricula > 0"
                                            class="text-success"
                                          > - R$ {{ formataPreco(fastSteps.step3.matricula.desconto_matricula) }}</span>
                                        </span>
                                        <span
                                          v-else
                                          class="text-success"
                                        >Gratuito</span>
                                      </p>
                                      <p>
                                        Total: <span v-if="fastSteps.step3.matricula.valor_matricula > 0">R$ {{ formataPreco(parseFloat(fastSteps.step3.matricula.valor_matricula) + parseFloat(fastSteps.step3.matricula.outros_valores) - parseFloat(fastSteps.step3.matricula.desconto_matricula)) }}</span>
                                        <span
                                          v-else
                                          class="text-success"
                                        >Gratuito</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-sm-12 col-md-12 col-lg-12">                                  
                                  <div class="row mb-4">
                                    <div class="col-sm-12 col-md-12 col-lg-6">
                                      <h4 class="text-secondary">
                                        Schema de comissão
                                      </h4>                          
                                      <select
                                        v-if="fastComissaoSchema.total.length"
                                        class="form-control"
                                        @change="getComissaoSchemaDeterminacoes"
                                      >
                                        <option
                                          value="0"
                                          selected
                                        >
                                          -- Nenhum schema de comissão selecionado --
                                        </option>
                                        <option
                                          v-for="c in fastComissaoSchema.total"
                                          :key="c.id_comissao_schema"
                                          :value="c.id_comissao_schema"
                                        >
                                          {{ c.nome_schema_comissao }}
                                        </option>
                                      </select>  
                                      <select
                                        v-else
                                        class="form-control"
                                      >
                                        <option value="">
                                          -- Nenhum schema de comissão cadastrado --
                                        </option>
                                      </select>    
                                    </div>
                                    <div v-if="!fastComissaoSchemaDeterminacoes.loading" class="col-sm-12 col-md-12 col-lg-12">                                         
                                      <div v-if="fastComissaoSchemaDeterminacoes.loading" class="row">
                                        <div class="col-12 mt-4 text-center">
                                          <span>Carregando itens...</span>
                                        </div>
                                      </div>
                                      <div v-else-if="fastComissaoSchemaDeterminacoes.total.length" class="row">
                                        <div class="col-12 table-responsive mt-4">
                                          <table class="table table-sm table-bordered">
                                            <thead class="thead-dark">
                                              <tr class="text-center">
                                                <th>
                                                  <small
                                                    class="font-weight-bold"
                                                  >Tipo determinação</small>
                                                </th>
                                                <th>
                                                  <small
                                                    class="font-weight-bold"
                                                  >Curso</small>
                                                </th>
                                                <th>
                                                  <small
                                                    class="font-weight-bold"
                                                  >Comissão</small>
                                                </th>
                                                <th>
                                                  <small
                                                    class="font-weight-bold"
                                                  >Comissão parcela</small>
                                                </th>
                                                <th>
                                                  <small
                                                    class="font-weight-bold"
                                                  >Comissão total</small>
                                                </th>
                                                <th>
                                                  <small
                                                    class="font-weight-bold"
                                                  ></small>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr v-for="(e, index) in fastComissaoSchemaDeterminacoes.total" :key="index">
                                                <td v-if="e.id_pessoa" class="align-middle text-center">
                                                  Pessoa específica
                                                </td>
                                                <td v-else-if="e.id_funcao_pessoa" class="align-middle text-center">
                                                  {{e.nome_funcao_pessoa}}
                                                </td>
                                                <td v-else class="align-middle text-center text-danger">
                                                  Não configurado
                                                </td>

                                                <td v-if="e.id_curso" class="align-middle text-center">
                                                  {{e.nome_curso ? e.nome_curso : e.nome_curso_mae }}
                                                </td>
                                                <td v-else-if="e.id_secretaria_curso" class="align-middle text-center">
                                                  {{e.nome_curso_secretaria}}
                                                </td>
                                                <td v-else-if="e.id_secretaria_curso_programa" class="align-middle text-center">
                                                  {{e.sigla_programa}}
                                                </td>
                                                <td v-else-if="e.id_secretaria_curso_programa_unidade" class="align-middle text-center">
                                                  {{e.nome_curso_unidade}}
                                                </td>
                                                <td v-else class="align-middle text-center">
                                                  Todos
                                                </td>

                                                <td v-if="e.percentual && e.percentual > 0" class="align-middle text-center">
                                                  {{e.percentual}}%
                                                </td>
                                                <td v-else-if="e.valor && e.valor > 0" class="align-middle text-center">
                                                  R$ {{formataPreco(e.valor)}}
                                                </td>
                                                <td v-else class="align-middle text-center text-danger">
                                                  Não configurado
                                                </td>

                                                <td class="align-middle text-center">
                                                  <span v-if="e.valor_comissao_parcela">
                                                    R$ {{formataPreco(e.valor_comissao_parcela)}}
                                                  </span>
                                                </td>

                                                <td class="align-middle text-center">
                                                  <span v-if="e.valor_comissao_total">
                                                    R$ {{formataPreco(e.valor_comissao_total)}}
                                                  </span>
                                                </td>

                                                <td v-if="e.id_pessoa" class="align-middle text-center">
                                                  {{e.nome_razao + ' ' + e.sobrenome_fantasia + ' (' + e.email + ')'}}
                                                  <span v-if="!e.payee_code_gerencianet"> - Sem conta gerencianet</span>
                                                </td>
                                                <td v-else-if="e.id_funcao_pessoa" class="align-middle text-center">                                                  
                                                  <select v-if="e.pessoas.length" class="form-control" @change="definePessoaComissao" :id="e.id_determinacao">
                                                    <option value="" class="text-center">
                                                      -- Selecione uma pessoa com a função {{e.nome_funcao_pessoa}} --
                                                    </option>
                                                    <option v-for="p in e.pessoas" :key="p.id_pessoa" :value="p.id_pessoa">
                                                      {{p.nome_razao + ' ' + p.sobrenome_fantasia + ' (' + p.email + ')'}}
                                                      <span v-if="!p.payee_code_gerencianet"> - Sem conta gerencianet</span>
                                                    </option>
                                                  </select>
                                                  <span v-else class="text-danger">
                                                    Nenhuma pessoa cadastrada com essa função
                                                  </span>
                                                </td>

                                              </tr>
                                            </tbody>
                                          </table>

                                        </div>
                                      </div>
                                      <div v-else class="col-12 mt-4 text-center">
                                        <span>Nenhum item adicionado</span>
                                      </div>  
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>                          
                        </div>
                      </div>
                    </tab-content>
                    <tab-content
                      title="Responsável"
                      :before-change="validaQuartoPasso"
                      icon
                    >
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">
                                4 - Responsável financeiro
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row mt-2 mb-2"> 
                            <div class="col-12">
                              <div
                                v-if="!fastSteps.step4.aluno_responsavel"
                                class="row mt-2"
                              >
                                  <fast-cadastro-pessoa-responsavel :id_plataforma="parseInt($route.params.id_plataforma)" :id_pessoa_aluno="parseInt($route.params.id_pessoa_aluno)"/>
                                                               
                              </div>
                              <div class="row mt-2">
                                <div class="col-12">
                                  <input
                                    v-model="fastSteps.step4.aluno_responsavel"
                                    type="checkbox"
                                    id="chkDefineResponsavelAluno"
                                    @change="defineAlunoResponsavel()"
                                  > <label for="chkDefineResponsavelAluno">O aluno será o responsável financeiro</label>
                                </div>
                              </div>
                            </div>                              
                          </div>
                        </div>
                      </div>
                    </tab-content>
                    <tab-content
                      title="Contrato"
                      :before-change="validaQuintoPasso"
                      icon
                    >
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">
                                5 - Geração e upload de contrato
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="this.fastSteps.step3.matricula.id_matricula && !statusGeracaoMatricula.iniciou"
                          class="card-body"
                        >
                        <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                          <input v-if="!fastGatewaysAssinatura.length || !fastSteps.step3.matricula.id_invoice" class="mx-2" type="checkbox" name="contrato-alternativo" id="contrato-alternativo" checked disabled>
                          <input v-else class="mx-2" type="checkbox" name="contrato-alternativo" id="contrato-alternativo" v-model="fastSteps.step5.metodoAlternativoContrato">
                          <label for="contrato-alternativo">Matrícula sem autenticação externa</label>
                        </div>
                          <div
                          v-if="fastGatewaysAssinatura.length && fastSteps.step3.matricula.id_invoice && !fastSteps.step5.metodoAlternativoContrato"
                          >
                            <div
                              v-if="fastSteps.step5.loading"
                              class="col-12 text-center"
                            >
                              <h6>Carregando...</h6>
                            </div>
                            <div
                              v-else                            >
                                <fast-cria-documentos 
                                  :id_pessoa="parseInt($route.params.id_pessoa_aluno)" 
                                  :id_plataforma="parseInt($route.params.id_plataforma)" 
                                  :id_matricula="parseInt(fastSteps.step3.matricula.id_matricula)"
                                  :id_secretaria_curso="parseInt(fastSteps.step1.curso.id_secretaria_curso)"
                                />
                            </div>
                          </div>
                          <!-- <div class="row mt-2 mb-4"> -->
                          <div
                            v-else
                            class="col-sm-12 col-md-12 col-lg-12"
                          >
                            <div v-if="fastSecretariaContratosCurso.length" class="row">
                              <div class="col-12">
                                <h5>Templates de contrato</h5>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-6">                                  
                                <select
                                  v-model="fastContratoEscolhido.id_contrato"
                                  class="form-control"
                                >
                                  <option
                                    v-for="contrato in fastSecretariaContratosCurso"
                                    :key="contrato.id_contrato"
                                    :value="contrato.id_contrato"
                                  >
                                    {{ contrato.nome_contrato }}
                                  </option>
                                </select>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <button
                                  class="btn btn-sm btn-primary mr-1 ml-1"
                                  @click="geraPdfContrato()"
                                >
                                  <small><b-icon-download /> Download</small>
                                </button>
                                <button
                                  class="btn btn-sm btn-primary mr-1 ml-1"
                                  @click="showModal('modalEnviarContratoEmail')"
                                >
                                  <small><b-icon-envelope /> Enviar por email</small>
                                </button>
                              </div>
                            </div>              
                            <div
                              class="col-sm-12 col-md-12 col-lg-12 mb-4 mt-4"
                              v-else
                            >
                              <div class="row mt-2 mb-2">
                                <div class="col-12">
                                  <h5>Upload e contrato</h5>
                                </div>
                                <div class="col-12">
                                  <uploader
                                    :file-status-text="statusText"
                                    :options="optionsArquivo"
                                    class="uploader-example"
                                    @file-success="fileSuccess"
                                    @file-added="fileValidation"
                                  >
                                    <uploader-unsupport />
                                    <uploader-drop>
                                      <p class="aluno_font_color">
                                        Arraste o contrato assinado aqui ou
                                      </p>
                                      <uploader-btn :attrs="restrictArquivo">
                                        Clique aqui para selecionar
                                      </uploader-btn>
                                    </uploader-drop>
                                    <uploader-list />
                                  </uploader>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- </div> -->
                        </div>
                        <div
                          v-else
                          class="card-body"
                        >
                          <div class="row mt-2 mb-4 text-center">
                            <div
                              v-if="!statusGeracaoMatricula.iniciou"
                              class="col-12"
                            >
                              <div class="row">
                                <div class="col-12 mb-2">
                                  <h5>Confirme a matrícula para gerar o contrato</h5>
                                </div>
                                <div class="col-12">
                                  <button
                                    class="btn btn-primary"
                                    @click="gerarMatricula()"
                                  >
                                    Gerar matrícula
                                  </button>                              
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="col-12 text-center"
                            >
                              <b>Aguarde a checagem...</b>
                            </div>                           
                            
                            <div class="col-12 table-responsive p-4 text-left">  
                              <table class="table table-sm table-striped">
                                <thead class="thead-dark">
                                  <tr>
                                    <th class="w-50">
                                      Checagens
                                    </th>
                                    <th class="w-50">
                                      Status
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Definição do curso</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.definicao_curso.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.definicao_curso.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_curso.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.definicao_curso.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_curso.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.definicao_curso.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_curso.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.definicao_curso.message }}</span>                                 
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Definição da turma</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.definicao_turma.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.definicao_turma.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_turma.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.definicao_turma.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_turma.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.definicao_turma.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_turma.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.definicao_turma.message }}</span>                                 
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Definição do plano do curso</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.definicao_plano.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.definicao_plano.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_plano.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.definicao_plano.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_plano.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.definicao_plano.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_plano.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.definicao_plano.message }}</span>                                 
                                    </td>
                                  </tr>                                   
                                  <tr>
                                    <td>Definição do plano de pagamento</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.definicao_plano_pagamento.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.definicao_plano_pagamento.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_plano_pagamento.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.definicao_plano_pagamento.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_plano_pagamento.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.definicao_plano_pagamento.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_plano_pagamento.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.definicao_plano_pagamento.message }}</span>                                 
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Definição do tipo de pagamento</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.definicao_tipo_pagamento.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.definicao_tipo_pagamento.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_tipo_pagamento.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.definicao_tipo_pagamento.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_tipo_pagamento.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.definicao_tipo_pagamento.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_tipo_pagamento.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.definicao_tipo_pagamento.message }}</span>                                 
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Definição do responsável</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.definicao_responsavel.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.definicao_responsavel.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_responsavel.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.definicao_responsavel.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_responsavel.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.definicao_responsavel.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.definicao_responsavel.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.definicao_responsavel.message }}</span>                                 
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Geração da matrícula</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.matricula_gerada.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.matricula_gerada.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.matricula_gerada.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.matricula_gerada.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.matricula_gerada.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.matricula_gerada.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.matricula_gerada.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.matricula_gerada.message }}</span>                                 
                                    </td>
                                  </tr>
                                  <tr v-if="fastSteps.step2.plano == 'modulo'">
                                    <td>Cadastro de módulos escolhidos</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.insercao_modulos.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.insercao_modulos.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_modulos.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.insercao_modulos.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_modulos.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.insercao_modulos.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_modulos.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.insercao_modulos.message }}</span>                                 
                                    </td>
                                  </tr>
                                  <tr v-if="fastSteps.step2.plano == 'unidade'">
                                    <td>Cadastro de unidades curriculares escolhidas</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.insercao_unidades.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.insercao_unidades.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_unidades.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.insercao_unidades.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_unidades.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.insercao_unidades.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_unidades.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.insercao_unidades.message }}</span>                                 
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Registro invoice</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.insercao_invoice.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.insercao_invoice.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_invoice.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.insercao_invoice.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_invoice.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.insercao_invoice.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_invoice.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.insercao_invoice.message }}</span>                                 
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Registro de parcelas</td>
                                    <td>  
                                      <span
                                        v-if="statusGeracaoMatricula.insercao_parcelas.status == 'ok'"
                                        class="text-success"
                                      >{{ statusGeracaoMatricula.insercao_parcelas.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_parcelas.status == 'error'"
                                        class="text-danger"
                                      >{{ statusGeracaoMatricula.insercao_parcelas.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_parcelas.status == 'executing'"
                                        class="text-warning"
                                      >{{ statusGeracaoMatricula.insercao_parcelas.message }}</span>
                                      <span
                                        v-else-if="statusGeracaoMatricula.insercao_parcelas.status == 'waiting'"
                                        class="text-info"
                                      >{{ statusGeracaoMatricula.insercao_parcelas.message }}</span>                                 
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab-content>
                    <tab-content
                      title="Faturas"
                      :before-change="validaSextoPasso"
                      icon
                    >
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">
                                6 - Faturas
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">                          
                          <div class="row mt-4 mb-2">
                            <div class="col-12">
                              <p class="mb-0">
                                <b>Nome do responsável financeiro:</b> {{ this.fastSteps.step3.invoice.nome_responsavel }}
                              </p>
                            </div>
                            <div class="col-12 mt-4">
                              <h4>Dados de pagamento da matrícula</h4>
                              <p class="mb-0">
                                <b>Valor da matrícula:</b> <span v-if="fastSteps.step3.matricula.valor_matricula > 0">R$ {{ formataPreco(fastSteps.step3.matricula.valor_matricula) }}</span><span
                                  v-else
                                  class="text-success"
                                >Gratuita</span>
                              </p>
                              <p v-if="fastSteps.step3.matricula.desconto_matricula" class="mb-0">
                                <b>Desconto:</b> <span> R$ {{ formataPreco(fastSteps.step3.matricula.desconto_matricula) }}</span>
                              </p>
                              <p v-if="fastSteps.step3.matricula.outros_valores" class="mb-0">
                                <b>Outros valores:</b> <span> R$ {{ formataPreco(fastSteps.step3.matricula.outros_valores) }}</span>
                              </p>
                              <p v-if="fastSteps.step3.matricula.valor_matricula" class="mb-0">
                                <b>Total</b> R$ <span>{{ retornaTotalMatricula(fastSteps.step3.matricula.valor_matricula, fastSteps.step3.matricula.desconto_matricula, fastSteps.step3.matricula.outros_valores)}}</span>
                              </p>
                              <p
                                v-if="fastSteps.step3.matricula.valor_matricula < 1"
                                class="mb-0"
                              >
                                <b>Pago:</b> <span
                                  v-if="fastSteps.step3.matricula.pago"
                                  class="text-success"
                                >Sim</span><span
                                  v-else
                                  class="text-danger"
                                >Não</span>
                              </p>
                              <p
                                v-if="fastSteps.step3.matricula.pago"
                                class="mb-0"
                              >
                                <b>Data do pagamento:</b> <span class="text-success">{{ fastSteps.step3.matricula.data_pagto }}</span>
                              </p>
                              <p class="mb-0">
                                <b>Ativa:</b> <span
                                  v-if="fastSteps.step3.matricula.ativo"
                                  class="text-success"
                                >Sim</span><span
                                  v-else
                                  class="text-danger"
                                >Não</span>
                              </p>
                              <p class="mb-0">
                                <b>Contrato:</b> <a
                                  v-if="fastSteps.step3.matricula.path_contrato"
                                  :href="fastSteps.step3.matricula.path_contrato"
                                  target="_blank"
                                >Download</a>
                                <span v-else class="text-warning">Pendente</span>
                              </p>
                              <div class="row mt-4">
                                <div class="col-12 table-responsive p-4">  
                                  <table class="table table-sm table-striped">
                                    <thead class="thead-dark">
                                      <tr>
                                        <th class="text-center">
                                          Parcela
                                        </th>
                                        <th class="text-center">
                                          Valor
                                        </th>
                                        <th class="text-center">
                                          Pago
                                        </th>
                                        <th class="text-center">
                                          Vencimento
                                        </th>
                                        <th class="text-center">
                                          Ações
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="fastSteps.step6.matricula.matriculaInvoiceParcela.length">
                                      <tr
                                        v-for="(parcela, index) in fastSteps.step6.matricula.matriculaInvoiceParcela"
                                        :key="index"
                                      >
                                        <td class="text-center">
                                          {{ parcela.parcela }}
                                        </td>
                                        <td class="text-center">
                                          R$ {{ formataPreco(parcela.valor_parcela) }}
                                        </td>
                                        <td class="text-center">
                                          <button
                                            v-if="parcela.pago"
                                            class="btn btn-sm btn-success pt-0 pb-0"
                                          >
                                            <small>Sim</small>
                                          </button>
                                          <button
                                            v-else
                                            class="btn btn-sm btn-danger pt-0 pb-0"
                                          >
                                            <small>Não</small>
                                          </button>
                                        </td>
                                        <td class="text-center">
                                          {{ formataDataT(parcela.data_vencimento) }}
                                        </td>
                                        <td class="text-center">
                                          <a v-if="parcela.path_boleto" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" :href="parcela.path_boleto" target="_blank">
                                            <small class="text-white">Download</small>
                                          </a>
                                          <span v-else class="text-secondary">
                                            <small>Boleto sem registro</small>
                                          </span>
                                          &nbsp;
                                          <a v-if="parcela.path_boleto && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S')" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" 
                                          :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria/emissao-boletos?idInvoice=' + parcela.id_invoice + '&parcela=' + parcela.parcela" 
                                          >
                                            <small class="text-white">Detalhes</small>
                                          </a>
                                          &nbsp;
                                          <button v-if="parcela.path_boleto" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" @click="exibeModalWhatsApp(parcela)">
                                            <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          colspan="5"
                                          class="text-center"
                                        >
                                          <span>Nenhuma parcela gerada</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 mt-4">
                              <h4>Dados de pagamento do curso</h4>
                              <p class="mb-0">
                                <b>Acréscimos:</b> R$ {{ formataPreco(fastSteps.step3.invoice.acrescimo) }}
                              </p>
                              <p class="mb-0">
                                <b>Desconto:</b> R$ {{ formataPreco(fastSteps.step3.invoice.desconto_total) }}
                              </p>
                              <p class="mb-0">
                                <b>Subtotal:</b> R$ {{ formataPreco(fastSteps.step3.invoice.subtotal) }}
                              </p>
                              <p class="mb-0">
                                <b>Total:</b> R$ {{ formataPreco(fastSteps.step3.invoice.total) }}
                              </p>
                              <p class="mb-0">
                                <b>Parcelas:</b> {{ fastSteps.step6.invoice.parcelas.length }}
                              </p>
                            </div>
                            <div
                              v-if="fastSteps.step6.invoice.loading"
                              class="col-12 text-center mt-4"
                            >
                              <h6>Carregando...</h6>
                            </div>
                            <div
                              v-else
                              class="col-12"
                            >
                              <div class="row">
                                <div class="col-12 table-responsive p-4">  
                                  <table class="table table-sm table-striped">
                                    <thead class="thead-dark">
                                      <tr>
                                        <th class="text-center">
                                          Parcela
                                        </th>
                                        <th class="text-center">
                                          Valor
                                        </th>
                                        <th class="text-center">
                                          Pago
                                        </th>
                                        <th class="text-center">
                                          Vencimento
                                        </th>
                                        <th class="text-center">
                                          Ações
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="fastSteps.step6.invoice.parcelas.length">
                                      <tr
                                        v-for="(parcela, index) in fastSteps.step6.invoice.parcelas"
                                        :key="index"
                                      >
                                        <td class="text-center">
                                          {{ parcela.parcela }}
                                        </td>
                                        <td class="text-center">
                                          R$ {{ formataPreco(parcela.valor_parcela) }}
                                        </td>
                                        <td class="text-center">
                                          <button
                                            v-if="parcela.pago"
                                            class="btn btn-sm btn-success pt-0 pb-0"
                                          >
                                            <small>Sim</small>
                                          </button>
                                          <button
                                            v-else
                                            class="btn btn-sm btn-danger pt-0 pb-0"
                                          >
                                            <small>Não</small>
                                          </button>
                                        </td>
                                        <td class="text-center">
                                          {{ formataDataT(parcela.data_vencimento) }}
                                        </td>
                                        <td class="text-center">
                                          <a v-if="parcela.path_boleto" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" :href="parcela.path_boleto" target="_blank">
                                            <small class="text-white">Download</small>
                                          </a>
                                          <span v-else class="text-secondary">
                                            <small>Boleto sem registro</small>
                                          </span>
                                          &nbsp;
                                          <a v-if="parcela.path_boleto && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S')" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" 
                                          :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria/emissao-boletos?idInvoice=' + parcela.id_invoice + '&parcela=' + parcela.parcela" 
                                          >
                                            <small class="text-white">Detalhes</small>
                                          </a>
                                          &nbsp;
                                          <button v-if="parcela.path_boleto" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" @click="exibeModalWhatsApp(parcela)">
                                            <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          colspan="5"
                                          class="text-center"
                                        >
                                          <span>Nenhuma parcela gerada</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div> 

                            
                          </div>

                        </div>
                      </div>
                    </tab-content>
                  </form-wizard>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->

    <modal
      name="modalDefinirPrimeiraParcela"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Defina a primeira parcela</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalDefinirPrimeiraParcela')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4 text-center">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <input
              v-model="dataPrimeiraParcela"
              type="date"
              class="form-control"
              @change="definePrimeiraParcela()"
            >
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEnviarContratoEmail"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Enviar contrato por email</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEnviarContratoEmail')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <p class="text-info">
            Certifique-se de revisar o contrato antes de enviá-lo.
          </p>
          <p>Ao realizar o upload do contrato um email será enviado para o aluno com link para o contrato. A personalização do email pode ser feita através da personalização da plataforma.</p>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h5>Upload do contrato</h5>
            </div>
            <div class="col-12">
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccessEmail"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste o contrato aqui ou
                  </p>
                  <uploader-btn :attrs="restrictArquivo">
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalErrosRequisicao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Erros encontrados</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalErrosRequisicao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 table-responsive">  
              <table class="table table-sm table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>Nº</th>
                    <th>Descrição do erro</th>
                  </tr>
                </thead>
                <tbody v-if="fastErros.length">
                  <tr v-for="(erro, index) in fastErros" :key="index">
                    <td>{{index + 1}}</td>
                    <td>{{erro}}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="2">Erro desconhecido. Contate um administrador.</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-secondary mr-2" @click="hideModal('modalErrosRequisicao')">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalWhatsApp"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Compartilhar link via WhatsApp</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalWhatsApp')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label class="font-weight-bold text-secondary">Telefone do aluno:</label>&nbsp; <span>{{fastLinkWhats.telefone_aluno}}</span>
              <a target="_blank" :href="retornaLinkWhats(fastLinkWhats.telefone_aluno, fastLinkWhats.path_boleto, fastLinkWhats.parcela, fastLinkWhats.valor_parcela_total, fastLinkWhats.data_vencimento, fastLinkWhats.nome_plataforma, fastLinkWhats.tipo)" class="btn btn-sm btn-success pb-0 pt-0 ml-2">
                <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>                                
              </a>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label class="font-weight-bold text-secondary">Telefone do responsável:</label>&nbsp; <span>{{fastLinkWhats.telefone_responsavel}}</span>
              <a target="_blank" :href="retornaLinkWhats(fastLinkWhats.telefone_responsavel, fastLinkWhats.path_boleto, fastLinkWhats.parcela, fastLinkWhats.valor_parcela_total, fastLinkWhats.data_vencimento, fastLinkWhats.nome_plataforma, fastLinkWhats.tipo)" class="btn btn-sm btn-success pb-0 pt-0 ml-2">
                <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>                                
              </a>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-secondary mr-2" @click="hideModal('modalWhatsApp');">
                Fechar
              </button>
            </div>
          </div>

        </div>
      </div>
    </modal>

    <VueHtml2pdf
      ref="html2Pdf"
      class="mx-auto"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="955px"
      :manual-pagination="false"
      :paginate-elements-by-height="10000"
    >
      <section slot="pdf-content">
        <div
          class="fast-pdf-html-template"
          v-html="fastContratoEscolhido.html_template"
        />
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard"; //https://binarcode.github.io/vue-form-wizard/#/
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueHtml2pdf from "vue-html2pdf"; //https://www.npmjs.com/package/vue-html2pdf
import FastCadastroPessoaResponsavel from "../components/FastCadastroPessoaResponsavel";
import FastCriaDocumentos from "../components/FastCriaDocumentos";


export default {
  name: "HomeInternoSecretariaMatricular",
  components: {
    FormWizard,
    TabContent,
    WizardStep,
    VueHtml2pdf,
    FastCadastroPessoaResponsavel,
    FastCriaDocumentos,
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Steps
      loadingWizard: true,
      errorMsg: null,
      count: 0,
      currentStep: 0,
      // Cursos
      fastSecretariaCursosTotal: [],
      fastSecretariaTurmasTotal: [],
      fastSecretariaModulosTotal: [],
      fastSecretariaUnidadesTotal: [],
      fastPlataformaAlunoResponsaveis: [],
      // Contrato
      fastSecretariaContratosCurso: [],
      fastContratoEscolhido: {
        id_contrato: "",
        html_template: ""
      },
      // Dados pessoais
      fastPessoa: {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        first_name: "",
        last_name: "",
        cpf_cnpj: "",
        datanascimento_abertura: null,
        sexo: "",
        uf: "",
        cep: "",
        logradouro: "",
        bairro: "",
        cidade: "",
        complemento: "",
        numero: "",
        telefone: "",
        alteracao_pendente: false,
      },
      // Upload arquivo histórico
      optionsArquivo: {
        target: settings.endApiFastEad + "api/fast_plataforma_aluno_documento/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_pessoa_aluno: this.$route.params.id_pessoa_aluno
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: ["pdf", "odt", "doc", "docx", "ppt", "pptx", "gif", "jpg", "jpeg", "png"],
        },
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept: "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.ms-powerpoint,image/*",
      },      
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Pagamento
      fastSecretariaPlanoPagamento: [],
      fastSecretariaTipoPagamento: [],
      dataPrimeiraParcela: "",
      // Steps
      fastSteps: {
        step1: {
          curso: {
            id_secretaria_curso: 0,
            carga_horaria: "",
            creditos_total: "",
            detalhe_curso: "",
            gera_certificado: "",
            id_plataforma: 0,
            logo_curso: "",
            nivel: "",
            nome_curso: "",
            preco: "",
            publicado: "",
            total_programas: "",
            venda_modulo: "",
            venda_unidade_curricular: "",
            possui_aproveitamento: ""
          },
          turma: {
            id_turma: 0,
            sigla_turma: "",
            turno: "",
            loading: true
          },
        },
        step2: {
          modulo: {
            lista: [],
            requisitos_obrigatorios: true,
            loading: true
          },
          unidade: {
            lista: [],
            requisitos_obrigatorios: true,
            loading: true
          },
          plano: "curso"
        },
        step3: {
          plano: {
            id_plano_pagamento: 0,
            nome_plano: "",
            obs: "",
            periocidade_cada_unidade: "",
            dia_mes: "",
            tempo_determinado: "",
            parcelas: [],
            loading: true
          },
          tipo: {
            id_tipo_pagamento: 0,
            nome_tipo_pagamento: "",
            forma_pagamento: "",
            loading: true
          },
          invoice: {
            id_plataforma: 0,
            id_invoice: 0,
            data_registro: null,
            id_usuario_gerou: 0,
            id_matricula: 0,
            nome_aluno: "",
            nome_responsavel: "",
            cpf_responsavel: "",
            cpf_aluno: "",
            cep_responsavel: "",
            endereco_responsavel_numero: "",
            endereco_responsavel_complemento: "",
            subtotal: 0.00,
            desconto_total: 0.00,
            acrescimo: 0.00,
            total: 0.00,
            ativo: "",
            cancelado: false,
            motivo_cancelamento: false,
            id_usuario_cancelamento: 0,
            data_cancelamento: null,
            obs: "",
            id_plano_pagamento: "",
            taxa_boleto: 0.00,
            taxa_cartao: 0.00,
            outras_taxas: 0.00,
            id_gateway: 0,
            id_pessoa_aluno: 0,
            tipo: "",
            parcelas: []
          },
          matricula: {
            id_matricula: 0,
            id_pessoa_aluno: 0,
            tipo_matricula: "",
            data_matricula: null,
            RA: null,
            id_usuario_cadastro: 0,
            ano_matricula: "",
            valor_matricula: 0.00,
            vencimento_matricula: null,
            url_boleto: null,
            pago: null,
            data_pagto: null,
            desconto_matricula: 0.00,
            outros_valores: 0.00,
            path_contrato: null,
            ativo: false,
            curso_definido: "",
            plano_pagto_definido: "",
            ajuste_definido: "",
            responsavel_definido: "",
            contrato_gerado: "",
            boleto_gerado: "",
            id_plataforma: 0,
            id_usuario_baixa_manual: 0,
            log_pagto_auditoria: null,
            id_turma: "",
            matricula_por_programa: "",
            matricula_por_unidade: "",
            total: 0.00,
            nome_curso: "",
            sigla_turma: "",
            qtde_modulos_compra: 0,
            qtde_unidades_compra: 0,   
            id_invoice: 0         
          },
          comissao: [],
          pular_pagamento: false
        },
        step4: {
          id_pessoa_responsavel: 0,
          nome_responsavel: "",
          telefone_responsavel: "",
          aluno_responsavel: false,
          loading: true
        },
        step5: {
          path_contrato: "",
          loading: true,
          metodoAlternativoContrato: true,
        },
        step6: {
          matricula: {
            matriculaInvoiceParcela: [],
            loading: true
          },
          invoice: {
            parcelas: [],
            loading: true

          }
        }
      },
      // Status
      statusGeracaoMatricula: {
        iniciou: false,
        definicao_curso: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        definicao_turma: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        definicao_plano: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },        
        definicao_plano_pagamento: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        definicao_tipo_pagamento: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        definicao_responsavel: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        matricula_gerada: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        insercao_modulos: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        insercao_unidades: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        insercao_invoice: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        insercao_parcelas: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
      },
      // pdf
      htmlToPdfOptions: {
        margin: 0.5,
        filename: 'Contrato.pdf',
        image: {
            type: 'jpeg',
            quality: 1
        },
        enableLinks: false,
        html2canvas: {
            scale: 1,
            scrollX: 0,
            scrollY: 0,
            useCORS: true,
            width: 960,
        },
        jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait'
        },
        //pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      },
      // Erros
      fastErros: [],
      // WhatsApp
      fastLinkWhats: {
        path_boleto: "",
        parcela: "",
        valor_parcela_total: "",
        data_vencimento: "",
        nome_plataforma: "",
        tipo: "",
        telefone_aluno: "",
        telefone_responsavel: ""
      },
      //Comissão dos professores
      fastProfessores: [],
      fastProfessorComissao: {
        id_professor: 0,
        comissao_porcent: 0,
        comissao_valor: 0,
        id_invoice: 0,
        id_plataforma: 0,
        loading: false
      },      
      fastComissaoSchema: {
        total: [],
        loading: true
      },
      fastComissaoSchemaDeterminacoes: {
        total: [],
        loading: true
      },
      fastGatewaysAssinatura: [],
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_pessoa_aluno) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          // Verifica gateway de pagamento
          this.getPlataformaGateway(this.$route.params.id_plataforma)

          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
             this.buscaInfoPessoa(this.$route.params.id_pessoa_aluno) 
            
            if (this.getUrlParameter('id_matricula_solicitacao')) {
              this.promiseGetFastApi("api/fast_secretaria_matricula_aluno_solicitacao/lista_admin", "id_plataforma=" + this.$route.params.id_plataforma + "&id_matricula_solicitacao=" + parseInt(this.getUrlParameter('id_matricula_solicitacao')))
              .then(res => {
                console.log("getSecretariaSolicitacoesMatricula", res);
                if (res.length) {
                  const solicitacao = res[0]    
                  this.fastSteps.step1.curso.id_secretaria_curso = solicitacao.id_secretaria_curso
                  this.fastSteps.step1.turma.id_turma = solicitacao.id_turma
                  
                  this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_secretaria_curso=" + this.fastSteps.step1.curso.id_secretaria_curso)
                  .then(json => {        
                    let obj = Array.from(json);        
                    if (obj.length) {  
                      obj = obj.filter(c => { if (c.publicado) return true })
                      this.fastSecretariaCursosTotal = obj;
                    } else {
                      this.fastSecretariaCursosTotal = [];
                    }        
                    this.$store.state.fastCarregando = false;
                  })
                  .then(() => {
                    this.getSecretariaTurmas()
                  })
                  .then(res => {
                    this.getSecretariaModulos(this.fastSteps.step1.turma.id_turma)
                  })
                  .then(res => {
                    if (solicitacao.matricula_por_programa) {
                      this.fastSteps.step2.plano = "modulo"
                      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno_programa_solicitacao/lista", "id_matricula_solicitacao=" + parseInt(this.getUrlParameter('id_matricula_solicitacao')))
                      .then(res => {
                        console.log("Módulos", res)
                          // Módulos
                          res.forEach(e => this.fastSteps.step2.modulo.lista.push(e.id_secretaria_curso_programa))

                          this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);
                          this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);

                          this.$refs.wizard.changeTab(0,1)
                          this.$refs.wizard.changeTab(0,2)
                      }).catch(e => {
                        this.exibeToasty(e, "error")
                      })
                    } else if (solicitacao.matricula_por_unidade) {
                      this.fastSteps.step2.plano = "unidade"
                      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno_unidade_solicitacao/lista", "id_matricula_solicitacao=" + parseInt(this.getUrlParameter('id_matricula_solicitacao')))
                      .then(res => {
                          // Unidades
                          res.forEach(e => this.fastSteps.step2.unidade.lista.push(e.id_secretaria_curso_programa_unidade))

                          this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);
                          this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);

                          this.$refs.wizard.changeTab(0,1)
                          this.$refs.wizard.changeTab(0,2)
                      }).catch(e => {
                        this.exibeToasty(e, "error")
                      })
                    } else {
                      // Curso completo
                      this.fastSteps.step2.plano = "curso"

                      this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);
                      this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);

                      this.$refs.wizard.changeTab(0,1)
                      this.$refs.wizard.changeTab(0,2)
                    }
                  })
                  .catch(e => {
                    console.log(e);
                    this.exibeToasty(e, "error")
                  })
                   
                  

                } else {
                  this.exibeToasty("Erro ao buscar informações da solicitação de matrícula", "error")
                }
              }).catch(e => {
                this.exibeToasty(e, "error")
              })
            } else {
              this.getSecretariaCursos(this.$route.params.id_plataforma)  
            }

            // Busca professores
            this.getProfessoresPlataforma(this.$route.params.id_plataforma) 

            // Busca comissão
            this.getComissaoSchemas(this.$route.params.id_plataforma)

            // Busca gateways de autenticação
            this.getGatewaysAssinatura()
          
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    //#region Gateway
    async getPlataformaGateway(id_plataforma){
      this.promiseGetFastApi("api/fast_plataforma_gateway/lista", `id_plataforma=${id_plataforma}`)
      .then((res) => {
        console.log("getPlataformaGateway", res)
        if (!res.length) {         
          if (this.$store.state.fastPermissoes.administrador == "S") {
            this.$toast.open({
              message: "<div>Nenhum gateway de pagamento configurado.</div> <div>Clique aqui e configure o gateway interno nos parâmetros da plataforma.</div>",
              type: "error",
              duration: 60000,
              dismissible: true,
              position: "bottom-right",
              onClick: this.redirecionaPersonalizarPlataforma
            });
          } else {
            this.$toast.open({
              message: "<div>Nenhum gateway de pagamento configurado.</div> <div>Consulte um administrador da plataforma.</div>",
              type: "error",
              duration: 60000,
              dismissible: true,
              position: "bottom-right"
            });
          }
          
        }
      })
      .catch(e => {
        this.exibeToasty(e, "error")
      })
    },
    redirecionaPersonalizarPlataforma(){
      this.$router.push('/plataforma/' + this.$route.params.id_plataforma +'/admin/personalizar-plataforma')
    },
    //#endregion
    
    // Dados pessoais do aluno
    async buscaInfoPessoa(id_pessoa) {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_usuario/busca_pessoa_secretaria?id_pessoa=" + id_pessoa + "&id_plataforma=" + this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();        
        let obj = JSON.parse(json);
        if (!obj.error) {
          if (obj.datanascimento_abertura)
            obj.datanascimento_abertura = obj.datanascimento_abertura.split(
              "T"
            )[0];
          else obj.datanascimento_abertura = "";
          if (!obj.first_name) obj.first_name = "";
          if (!obj.last_name) obj.last_name = "";
          if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
          if (!obj.cep) obj.cep = "";
          if (!obj.cidade) obj.cidade = "";
          if (!obj.complemento) obj.complemento = "";
          if (!obj.logradouro) obj.logradouro = "";
          if (!obj.numero) obj.numero = "";
          if (!obj.sexo) obj.sexo = "";
          if (!obj.telefone) obj.telefone = "";
          if (!obj.uf) obj.uf = "";
          if (!obj.bairro) obj.bairro = "";

          this.fastPessoa = obj;

          if (!this.fastPessoa.id_usuario) this.exibeToasty("Não foi possível identificar o Id_usuario. Consulte um administrador do sistema.", "error");
          if (!this.fastPessoa.id_pessoa) this.exibeToasty("Não foi possível identificar o Id_pessoa. Consulte um administrador do sistema.", "error");
          if (!this.fastPessoa.email) this.exibeToasty("Não foi possível identificar o email do usuário. Consulte um administrador do sistema.", "error");
          if (!this.fastPessoa.telefone) this.exibeToasty("Não foi possível identificar o telefone do usuário. Consulte um administrador do sistema.", "error");
        }
        console.log(this.fastPessoa);
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Lista de usuários
    async getProfessoresPlataforma(id_plataforma){     
      this.promiseGetFastApi("api/fast_plataforma_professor/lista_semcom_usuario", `id_plataforma=${id_plataforma}`).then(obj => {
        console.log("getProfessoresPlataforma", obj)
        if (obj.length) {
          this.fastProfessores = obj
        }
        else {
          this.fastProfessores = []
        }          
      }).catch(e => {         
        this.exibeToasty(e, "error");
      })
    },
    // Primeiro passo
    getSecretariaCursos(id_plataforma){
      if (!this.fastSecretariaCursosTotal.length) {
        this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=0").then(json => {        
          let obj = Array.from(json);        
          console.log("getSecretariaCursos", obj)
          if (obj.length) {  
            obj = obj.filter(c => { if (c.publicado) return true })
            // Ordena pelo nome do curso
            obj.sort(this.reornedarArrayObjetos("nome_curso"))
            this.fastSecretariaCursosTotal = obj;
          } else {
            this.fastSecretariaCursosTotal = [];
          }        
          this.$store.state.fastCarregando = false;
        }).catch(e => {
          console.log(e);
        })
      }      
    },
    getSecretariaTurmas() {
      // Só reseta id_turma se não tiver vindo da solicitação de matrícula
      if (!this.getUrlParameter('id_matricula_solicitacao'))  this.fastSteps.step1.turma.id_turma = 0 

      if (this.fastSteps.step1.curso.id_secretaria_curso) {
        this.fastSecretariaCursosTotal.forEach(c => {
          if (c.id_secretaria_curso == this.fastSteps.step1.curso.id_secretaria_curso) {
            this.fastSteps.step1.curso.carga_horaria = c.carga_horaria;
            this.fastSteps.step1.curso.creditos_total = c.creditos_total;
            this.fastSteps.step1.curso.detalhe_curso = c.detalhe_curso;
            this.fastSteps.step1.curso.gera_certificado = c.gera_certificado;
            this.fastSteps.step1.curso.id_plataforma = c.id_plataforma;
            this.fastSteps.step1.curso.logo_curso = this.ajustaLinkApiAntiga(c.logo_curso);
            this.fastSteps.step1.curso.nivel = c.nivel;
            this.fastSteps.step1.curso.nome_curso = c.nome_curso;
            this.fastSteps.step1.curso.preco = c.preco;
            this.fastSteps.step1.curso.publicado = c.publicado;
            this.fastSteps.step1.curso.total_programas = c.total_programas;
            this.fastSteps.step1.curso.venda_modulo = c.venda_modulo;
            this.fastSteps.step1.curso.venda_unidade_curricular = c.venda_unidade_curricular;            
            this.fastSteps.step1.curso.possui_aproveitamento = c.possui_aproveitamento; 
          }
        })
        this.fastSteps.step1.turma.loading = true;
        this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=0").then(json => {
          let obj = Array.from(json);  
          console.log("getSecretariaTurmas", obj)
          if (obj.length) {  
            this.fastSecretariaTurmasTotal = obj.filter(e =>  e.id_secretaria_curso === this.fastSteps.step1.curso.id_secretaria_curso)           
          } else {
            this.fastSecretariaTurmasTotal = [];
          }
          this.fastSteps.step1.turma.loading = false;

        }).catch(e => {
          console.log(e);
        })
      } else {
        this.fastSteps.step1.modulo.id_turma = 0;
        this.fastSteps.step1.modulo.id_programa_turma = 0;
        this.fastSteps.step1.turma.loading = false;
      }
    },
    async validaPrimeiroPasso() {
      return new Promise(async (resolve, reject) => {
          if (this.fastSteps.step1.curso.id_secretaria_curso && this.fastSteps.step1.turma.id_turma) {   
            if (this.fastSteps.step1.curso.preco) {
              this.getSecretariaModulos(this.fastSteps.step1.turma.id_turma)
              this.fastSecretariaTurmasTotal.forEach(t => {
                if (t.id_turma == this.fastSteps.step1.turma.id_turma) {
                  this.fastSteps.step1.turma.sigla_turma = t.sigla_turma

                  if (t.turno == 1) this.fastSteps.step1.turma.turno = "Manhã"
                  else if (t.turno == 1) this.fastSteps.step1.turma.turno = "Tarde"
                  else if (t.turno == 1) this.fastSteps.step1.turma.turno = "Noite"
                  else this.fastSteps.step1.turma.turno = "Sem turno"
                }
              })
              resolve(true);
              return true;
            } else {
              this.exibeToasty("O curso não possui preço definido", "error");
              reject(true)
              return false
            }       
          } else {
            this.exibeToasty("Escolha um curso e uma turma", "error");
            reject(true)
            return false
          }          
      });
    },
    // Segundo passo
    getSecretariaModulos(id_turma){
      this.fastSteps.step2.modulo.loading = true;
      this.promiseGetFastApi("api/fast_secretaria_turma_programa/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=" + id_turma).then(json => {        
        let obj = Array.from(json);        
        console.log("getSecretariaModulos", obj)
        if (obj.length) {  
          obj.forEach(u => {
            u.unidades_curriculares = []
            if (u.data_inicio) u.data_inicio = u.data_inicio.split("T")[0]
          })
          this.fastSecretariaModulosTotal = obj;

          // Busca unidades curriculares
          this.getSecretariaUnidadesCurriculares();
        } else {
          this.fastSecretariaModulosTotal = [];
        }        
        this.fastSteps.step2.modulo.loading = false;
      }).catch(e => {
        console.log(e);
      })
    },
    getSecretariaUnidadesCurriculares() {
        this.fastSteps.step2.unidade.loading = true;
        this.promiseGetFastApi("api/fast_secretaria_turma_programa_unidade/lista_todas", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=" + this.fastSteps.step1.turma.id_turma).then(json => {
          let obj = Array.from(json);  
          console.log("getSecretariaUnidadesCurriculares", obj)
          if (obj.length) {              
            obj.forEach(u => {
              this.fastSecretariaModulosTotal.forEach(m => {
                if (m.id_secretaria_curso_programa == u.id_secretaria_curso_programa) m.unidades_curriculares.push(u)
              })
              if (u.data_inicio) u.data_inicio = u.data_inicio.split("T")[0]
            })            
            this.fastSecretariaUnidadesTotal = obj         
          } else {
            this.fastSecretariaUnidadesTotal = [];
          }
          this.fastSteps.step2.unidade.loading = false;

          //console.log("fastSecretariaModulosTotal", this.fastSecretariaModulosTotal)
        }).catch(e => {
          console.log(e);
        })
      
    },
    selecionaModulo(modulo) {
      // Se módulo já está incluído na lista
      if (this.fastSteps.step2.modulo.lista.includes(modulo.id_secretaria_curso_programa)) {
        if (!this.fastSteps.step2.modulo.requisitos_obrigatorios) {
          // Remove módulo da lista
          this.fastSteps.step2.modulo.lista = this.fastSteps.step2.modulo.lista.filter(function(item) {
              return item !== modulo.id_secretaria_curso_programa
          })
        } else {
          // Remove todos os módulos que possuem esse módulo como pré-requisito
          this.validaRemoverModulo(modulo)
        }        
      } else {
        // Se tem pré-requisito
        if (modulo.id_pai && this.fastSteps.step2.modulo.requisitos_obrigatorios) {
          this.validaAdicionarModulo(modulo)
        } 
        // Adiciona módulo da lista
        this.fastSteps.step2.modulo.lista.push(modulo.id_secretaria_curso_programa)  
      }

      if (this.fastSteps.step2.modulo.lista.length == this.fastSecretariaModulosTotal.length) this.exibeToasty("Você selecionou todos os módulos do curso!", "info");
    },
    validaAdicionarModulo(modulo) {
      // Se o pré-requisito não está na lista adiciona
      if (!this.fastSteps.step2.modulo.lista.includes(modulo.id_pai)) {
        this.fastSteps.step2.modulo.lista.push(modulo.id_pai)

        // Adiciona unidades curriculares
        this.fastSecretariaUnidadesTotal.forEach(t => {
          if (t.id_secretaria_curso_programa == modulo.id_pai && !this.fastSteps.step2.unidade.lista.includes(t.id_secretaria_curso_programa_unidade)) {
            this.fastSteps.step2.unidade.lista.push(t.id_secretaria_curso_programa_unidade)
          }
        });

        // Verifica se modulo atual possui pai
        this.fastSecretariaModulosTotal.forEach(t => {
          if (t.id_secretaria_curso_programa == modulo.id_pai) {
            if (t.id_pai) {
              this.validaAdicionarModulo(t)
            }
          }
        });
      }
    },
    validaRemoverModulo(modulo) {
      this.fastSteps.step2.modulo.lista.forEach(m => {
        this.fastSecretariaModulosTotal.forEach(t => {
          if (m == t.id_secretaria_curso_programa) {
            if (t.id_pai == modulo.id_secretaria_curso_programa) {               
              this.validaRemoverModulo(t)
            } else {
              // Remove módulo da lista
              this.fastSteps.step2.modulo.lista = this.fastSteps.step2.modulo.lista.filter(function(item) {
                  return item !== modulo.id_secretaria_curso_programa
              })
            } 
          }
        })          
      })
    },
    calculaPrecoTotal(array, tipo) {
      
      let valorTotal = 0.00;
      if (tipo == 'modulo') {
        array.forEach(id_secretaria_curso_programa => {
          this.fastSecretariaModulosTotal.forEach(t => {
            if (t.id_secretaria_curso_programa == id_secretaria_curso_programa) {
              if (t.preco) {
                valorTotal += t.preco
              }              
            }
          });          
        })
      } else {
        array.forEach(id_secretaria_curso_programa_unidade => {
          this.fastSecretariaUnidadesTotal.forEach(t => {
            if (t.id_secretaria_curso_programa_unidade == id_secretaria_curso_programa_unidade) {
              if (t.preco) {
                valorTotal += t.preco
              }
            }
          });
          
        })
      }
      
      return valorTotal
    },
    selecionaUnidade(unidade) {
      // Se módulo já está incluído na lista
      if (this.fastSteps.step2.unidade.lista.includes(unidade.id_secretaria_curso_programa_unidade)) {
        if (!this.fastSteps.step2.unidade.requisitos_obrigatorios) {
          // Remove módulo da lista
          this.fastSteps.step2.unidade.lista = this.fastSteps.step2.unidade.lista.filter(function(item) {
              return item !== unidade.id_secretaria_curso_programa_unidade
          })
        } else {
          // Remove todos os módulos que possuem esse módulo como pré-requisito
          this.validaRemoverUnidadeCurricular(unidade)
        }        
      } else {
        // Se tem pré-requisito
        if (unidade.id_pai && this.fastSteps.step2.unidade.requisitos_obrigatorios) {
          this.validaAdicionarUnidadeCurricular(unidade)
        } 
        // Adiciona módulo da lista
        this.fastSteps.step2.unidade.lista.push(unidade.id_secretaria_curso_programa_unidade)  
      }

      if (this.fastSteps.step2.unidade.lista.length == this.fastSecretariaUnidadesTotal.length) {
        this.exibeToasty("Você selecionou todas as unidades dos módulos!", "info");
      }
    },
    validaAdicionarUnidadeCurricular(unidade) {
      // Verifica requisitos do módulo
      this.fastSecretariaModulosTotal.forEach(t => {
        if (t.id_secretaria_curso_programa == unidade.id_secretaria_curso_programa) {
          if (t.id_pai) {
            this.validaAdicionarModulo(t)
          }
        }
      });

      // Se o pré-requisito não está na lista adiciona
      if (!this.fastSteps.step2.unidade.lista.includes(unidade.id_pai)) {
        this.fastSteps.step2.unidade.lista.push(unidade.id_pai)
        this.fastSecretariaUnidadesTotal.forEach(t => {
          if (t.id_secretaria_curso_programa_unidade == unidade.id_pai) {
            if (t.id_pai) {
              this.validaAdicionarUnidadeCurricular(t)
            }
          }
        });
      }

    },
    validaRemoverUnidadeCurricular(unidade) {
      this.fastSteps.step2.unidade.lista.forEach(m => {
        this.fastSecretariaUnidadesTotal.forEach(t => {
          if (m == t.id_secretaria_curso_programa_unidade) {
            if (t.id_pai == unidade.id_secretaria_curso_programa_unidade) {               
              this.validaRemoverUnidadeCurricular(t)
            } else {
              // Remove unidade da lista
              this.fastSteps.step2.unidade.lista = this.fastSteps.step2.unidade.lista.filter(function(item) {
                  return item !== unidade.id_secretaria_curso_programa_unidade
              })
            } 
          }
        })          
      })
    },
    async validaSegundoPasso() {
      return new Promise(async (resolve, reject) => {        

        switch (this.fastSteps.step2.plano) {
          case "curso":            
            // Buscando as formas de pagamento
            this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);
            this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);
            resolve(true);
            return true;
            break;
          case "modulo":
            if (this.fastSteps.step2.modulo.lista.length) {
              // Buscando as formas de pagamento
              this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);
              this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);
              resolve(true);
              return true;
            } else {
              this.exibeToasty("Escolha um módulo", "error");
              reject(true)
              return false
            }
            break;
          case "unidade":
            if (this.fastSteps.step2.unidade.lista.length) {
              // Buscando as formas de pagamento
              this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);
              this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);
              resolve(true);
              return true;
            } else {
              this.exibeToasty("Escolha uma unidade curricular", "error");
              reject(true)
              return false
            }
            break;        
          default:
            this.exibeToasty("Dados incompletos", "error");
            return false
            break;
        }

      });
    },
    // Terceiro passo
    getSecretariaPlanoPagamento(id_plataforma){
      if (!this.fastSecretariaPlanoPagamento.length) {
        this.fastSteps.step3.plano.loading = true;
        this.promiseGetFastApi("api/fast_tesouraria_plano_pagamento/lista", "id_plataforma=" + id_plataforma).then(obj => {
          console.log("getSecretariaPlanoPagamento", obj);
          if (obj.length) {
            obj = obj.filter(p => {if (p.ativo) return true})
            this.fastSecretariaPlanoPagamento = obj;
          }
          else this.fastSecretariaPlanoPagamento = [];
          this.fastSteps.step3.plano.loading = false
        }).catch(e => {
          console.log(e);
          this.fastSteps.step3.plano.loading = false
        })
      }      
    },
    getSecretariaTipoPagamento(id_plataforma){
      if (!this.fastSecretariaTipoPagamento.length) {
        this.fastSteps.step3.tipo.loading = true;
        this.promiseGetFastApi("api/fast_tesouraria_tipo_pagamento/lista", "id_plataforma=" + id_plataforma).then(obj => {
          console.log("getSecretariaTipoPagamento", obj);
          if (obj.length) {
            // Filtra somente débito
            this.fastSecretariaTipoPagamento = obj.filter(p => { if (p.operador == 'D') return true });
            //this.fastSecretariaTipoPagamento = obj
          }
          else {
            this.fastSecretariaTipoPagamento = []
          }

          this.fastSteps.step3.tipo.loading = false;
        }).catch(e => {
          console.log(e);
          this.fastSteps.step3.tipo.loading = false;
        })
      }      
    },
    formataFormaPagamento(forma_pagamento) {
      switch (forma_pagamento) {
        case 'C':
          return 'Cartão'
          break;
        case 'B':
          return 'Boleto bancário'
          break;
        default:
          break;
      }
    },
    verificaConfiguracaoPagamento() {     
      if (this.fastSteps.step3.tipo.id_tipo_pagamento && this.fastSteps.step3.plano.id_plano_pagamento) {
        let erros = []

        let valor_total = 0.00

        if (this.fastSteps.step2.plano == "curso") {
          valor_total = this.fastSteps.step1.curso.preco;
        } else if (this.fastSteps.step2.plano == "modulo") {
          valor_total = this.calculaPrecoTotal(this.fastSteps.step2.modulo.lista, "modulo");
        } else if (this.fastSteps.step2.plano == "unidade") {
          valor_total = this.calculaPrecoTotal(this.fastSteps.step2.unidade.lista, "unidade");
        }  

        // Pega o valor subtotal e total dos itens de cobraça
        if (!valor_total) erros.push("Valor total da fatura não identificado")

        // Busca o tipo selecionado
        this.fastSecretariaTipoPagamento.forEach(p => {
          if (p.id_tipo_pagamento == this.fastSteps.step3.tipo.id_tipo_pagamento) {
            this.fastSteps.step3.tipo.forma_pagamento = p.forma_pagamento
            this.fastSteps.step3.tipo.id_plano_conta = p.id_plano_conta
            this.fastSteps.step3.tipo.id_plataforma = p.id_plataforma
            this.fastSteps.step3.tipo.nome_tipo_pagamento = p.nome_tipo_pagamento
            this.fastSteps.step3.tipo.operador = p.operador
          }
        });
        if (!this.fastSteps.step3.tipo.forma_pagamento) erros.push("Tipo de pagamento não identificado")
        if (this.fastSteps.step3.tipo.forma_pagamento == "C") erros.push("O tipo de pagamento cartão de crédito não está ativado")

        // Busca o plano selecionado
        this.fastSecretariaPlanoPagamento.forEach(p => {
          if (p.id_plano_pagamento == this.fastSteps.step3.plano.id_plano_pagamento) {
            this.fastSteps.step3.plano.nome_plano = p.nome_plano
            this.fastSteps.step3.plano.obs = p.obs
            this.fastSteps.step3.plano.periocidade_cada_unidade = p.periocidade_cada_unidade
            this.fastSteps.step3.plano.dia_mes = p.dia_mes
            this.fastSteps.step3.plano.tempo_determinado = p.tempo_determinado
            this.fastSteps.step3.plano.parcelas = []
          }
        });
        if (!this.fastSteps.step3.plano.nome_plano) erros.push("Plano de pagamento não identificado")      
        if (!this.fastSteps.step3.plano.tempo_determinado || this.fastSteps.step3.plano.tempo_determinado < 1) erros.push("Plano de pagamento para tempo indeterminado não está ativo")  

        if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastSteps.step3.plano.id_plano_pagamento = 0
          this.fastSteps.step3.tipo.id_tipo_pagamento = 0
        } else {
          this.aplicaAjusteValor()
        }
      }
/*
      let dataAtual = new Date();
      this.promiseGetFastApi("api/fast_usuario_curso_aula_atividade/lista_hora", "").then(obj => {
        if (JSON.parse(obj).dataAtual) {
          dataAtual = new Date(JSON.parse(obj).dataAtual);
          if (this.dataPrimeiraParcela) dataAtual = new Date(this.dataPrimeiraParcela.split("-")[0] + "/" + this.dataPrimeiraParcela.split("-")[1] + "/" + this.dataPrimeiraParcela.split("-")[2])
          // Evita problema de fevereiro
          if (dataAtual.getDate() > 28) { 
            dataAtual.setMonth(dataAtual.getMonth() + 1)
            dataAtual.setDate(1)
          }
          this.fastSecretariaPlanoPagamento.forEach(p => {
          if (p.id_plano_pagamento == this.fastSteps.step3.plano.id_plano_pagamento) {
            this.fastSteps.step3.plano.nome_plano = p.nome_plano
            this.fastSteps.step3.plano.obs = p.obs
            this.fastSteps.step3.plano.periocidade_cada_unidade = p.periocidade_cada_unidade
            this.fastSteps.step3.plano.dia_mes = p.dia_mes
            this.fastSteps.step3.plano.tempo_determinado = p.tempo_determinado

            this.fastSteps.step3.plano.parcelas = []
            let valor_total = 0.00;   
            if (this.fastSteps.step2.plano == "curso") {
              valor_total = this.fastSteps.step1.curso.preco;
            } else if (this.fastSteps.step2.plano == "modulo") {
              valor_total = this.calculaPrecoTotal(this.fastSteps.step2.modulo.lista, "modulo");
            } else if (this.fastSteps.step2.plano == "unidade") {
              valor_total = this.calculaPrecoTotal(this.fastSteps.step2.unidade.lista, "unidade");
            }         

            this.fastSteps.step3.invoice.subtotal = valor_total
            this.fastSteps.step3.invoice.total = valor_total

            if (this.fastSteps.step3.plano.tempo_determinado) {
              let n_parcelas = 0.00
              n_parcelas = this.fastSteps.step3.plano.tempo_determinado / this.fastSteps.step3.plano.periocidade_cada_unidade 

              for (let index = 0; index < n_parcelas; index++) {
                if (this.fastSteps.step3.plano.dia_mes == "M") {
                  if (index) {       
                      dataAtual.setMonth( dataAtual.getMonth() + this.fastSteps.step3.plano.periocidade_cada_unidade );  
                  }
                } else if (this.fastSteps.step3.plano.dia_mes == "D") {
                  if (index) dataAtual = this.addDays(dataAtual, this.fastSteps.step3.plano.periocidade_cada_unidade );
                }

                let objParcela = {
                  parcela: index + 1,
                  total: valor_total,
                  valor_parcela: this.removeCasasDecimais(parseFloat(valor_total / n_parcelas), 2),
                  datetime: dataAtual,
                  data_formatada: dataAtual.toLocaleString('pt-BR').split(" ")[0],
                  data_vencimento: dataAtual.toLocaleString('pt-BR').split(" ")[0],
                  acrescimo: 0.00,
                  desconto: 0.00,
                  taxa_boleto: 0.00,
                  taxa_cartao: 0.00,
                  outras_taxas: 0.00,
                  nr_parcelas: n_parcelas                  
                }       
                                
                this.fastSteps.step3.plano.parcelas.push(objParcela)
                console.log("Valor da parcela: ", objParcela.valor_parcela)
              }

              if (this.fastSteps.step3.plano.parcelas.length) {                
                let parcelasSomadas = this.fastSteps.step3.plano.parcelas.length * parseFloat(this.fastSteps.step3.plano.parcelas[0].valor_parcela)               
                console.log("parcelasSomadas", parcelasSomadas)
                if (parseFloat(valor_total) > parseFloat(parcelasSomadas).toFixed(2)) {
                  let incremento = parseFloat(valor_total) - parseFloat(parseFloat(parcelasSomadas).toFixed(2))
                  this.fastSteps.step3.plano.parcelas[0].valor_parcela = parseFloat(this.fastSteps.step3.plano.parcelas[0].valor_parcela) + parseFloat(incremento.toFixed(2))
                } 
              }

              if (this.dataPrimeiraParcela) this.aplicaAcrescimo('total')
            }

          }
          })
        } else {
          this.exibeToasty("Erro ao recuperar data do servidor", "error");
        }
        
      })
      */
      
    },    
    aplicaAjusteValor(){
      let erros = []

      // Ajustes do valor total, o subtotal nunca é alterado
      let valor_total = 0.00

      if (this.fastSteps.step2.plano == "curso") {
        valor_total = this.fastSteps.step1.curso.preco;
      } else if (this.fastSteps.step2.plano == "modulo") {
        valor_total = this.calculaPrecoTotal(this.fastSteps.step2.modulo.lista, "modulo");
      } else if (this.fastSteps.step2.plano == "unidade") {
        valor_total = this.calculaPrecoTotal(this.fastSteps.step2.unidade.lista, "unidade");
      }         
      this.fastSteps.step3.invoice.subtotal = valor_total

      if (this.fastSteps.step3.invoice.acrescimo == "") this.fastSteps.step3.invoice.acrescimo = 0.00
      if (this.fastSteps.step3.invoice.desconto_total == "") this.fastSteps.step3.invoice.desconto_total = 0.00
      valor_total = parseFloat(this.fastSteps.step3.invoice.subtotal) + parseFloat(this.fastSteps.step3.invoice.acrescimo) - parseFloat(this.fastSteps.step3.invoice.desconto_total)      
      
      let n_parcelas = 0
      n_parcelas = this.fastSteps.step3.plano.tempo_determinado / this.fastSteps.step3.plano.periocidade_cada_unidade 
      let valor_parcela = parseFloat(valor_total / n_parcelas)
      // Arredonda pra cima
      valor_parcela = this.arredondaCasasDecimais(valor_parcela)  

      // Se existir taxas nas parcelas incrementa no valor total
      let taxa_boleto = 0.00
      if (this.fastSteps.step3.invoice.taxa_boleto) taxa_boleto = parseFloat(this.fastSteps.step3.invoice.taxa_boleto)
      let taxa_cartao = 0.00
      if (this.fastSteps.step3.invoice.taxa_cartao) taxa_cartao = parseFloat(this.fastSteps.step3.invoice.taxa_cartao)              
      let outras_taxas = 0.00
      if (this.fastSteps.step3.invoice.outras_taxas) outras_taxas = parseFloat(this.fastSteps.step3.invoice.outras_taxas)
      let taxas_parcela = (taxa_boleto * n_parcelas) + (taxa_cartao * n_parcelas) + (outras_taxas * n_parcelas)

      this.fastSteps.step3.invoice.total = (valor_parcela * n_parcelas) + taxas_parcela      

      // O mínimo valor da parcela é 5 (restrição do gerencianet)
      if (valor_parcela < 5) erros.push("O valor mínimo da parcela é de R$ 5,00")

      // Se o plano possui tempo determinado
      if (!this.fastSteps.step3.plano.tempo_determinado) erros.push("Plano sem tempo determinado não é suportado no momento")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
        this.fastSteps.step3.plano.id_plano_pagamento = 0
        this.fastSteps.step3.tipo.id_tipo_pagamento = 0
      } else {
         let dataAtual = new Date()
          // Captura a data do servidor para cálculo das datas das parcelas
          this.getFastHoraServidor()
          .then(() => {
            // Evita problema de fevereiro
            dataAtual = this.$store.state.fastDataAtualServidor          
            if (dataAtual.getDate() > 28) { 
              dataAtual.setMonth(dataAtual.getMonth() + 1)
              dataAtual.setDate(1)
            } 
            if (this.dataPrimeiraParcela) dataAtual = new Date(this.dataPrimeiraParcela.split("-")[0] + "/" + this.dataPrimeiraParcela.split("-")[1] + "/" + this.dataPrimeiraParcela.split("-")[2])
            return dataAtual
          })
          .then(dataAtual => {    
              // Monta as parcelas
              this.fastSteps.step3.plano.parcelas = []
              this.fastSteps.step3.invoice.parcelas = []
              for (let index = 0; index < n_parcelas; index++) {

                // Se periodicidade for mês seta do vencimento por mês
                if (this.fastSteps.step3.plano.dia_mes == "M") {
                  if (index) {       
                      dataAtual.setMonth( dataAtual.getMonth() + this.fastSteps.step3.plano.periocidade_cada_unidade );  
                  }
                // Se periodicidade for dia seta do vencimento por dia
                } else if (this.fastSteps.step3.plano.dia_mes == "D") {
                  if (index) dataAtual = this.addDays(dataAtual, this.fastSteps.step3.plano.periocidade_cada_unidade );
                }

                // Cria objeto da parcela
                let objParcela = {
                  parcela: index + 1,
                  total: this.fastSteps.step3.invoice.total,
                  valor_parcela: valor_parcela,
                  datetime: dataAtual,
                  data_formatada: dataAtual.toLocaleString('pt-BR').split(",")[0],
                  data_vencimento: dataAtual.toLocaleString('pt-BR').split(",")[0],
                  acrescimo: 0.00,
                  desconto: 0.00,
                  taxa_boleto: taxa_boleto,
                  taxa_cartao: taxa_cartao,
                  outras_taxas: outras_taxas,
                  nr_parcelas: n_parcelas                  
                } 
                
                // Adiciona objeto parcela no array de parcelas do plano e invoice
                this.fastSteps.step3.plano.parcelas.push(objParcela)
                this.fastSteps.step3.invoice.parcelas.push(objParcela)

              }

              // Chama as comissões novamente
              this.getComissaoSchemas(this.$route.params.id_plataforma)
          })
          .catch((e) => this.exibeToasty(e, "error"))
      }
    },
    definePrimeiraParcela() {
      if (this.dataPrimeiraParcela.split("-").length) {
        this.verificaConfiguracaoPagamento()
        this.hideModal("modalDefinirPrimeiraParcela")
      }      
    },
    aplicaAcrescimo(destino) {      
      let total = 0.00

      if (this.fastSteps.step3.invoice.acrescimo == "") this.fastSteps.step3.invoice.acrescimo = 0.00
      if (this.fastSteps.step3.invoice.desconto_total == "") this.fastSteps.step3.invoice.desconto_total = 0.00
      if (this.fastSteps.step3.invoice.total == "") this.fastSteps.step3.invoice.total = 0.00

      if (destino == 'total') {
        total = parseFloat(this.fastSteps.step3.invoice.subtotal) + parseFloat(this.fastSteps.step3.invoice.acrescimo) - parseFloat(this.fastSteps.step3.invoice.desconto_total)
        this.fastSteps.step3.invoice.total = total
        this.aplicaAjusteParcelas();
      } 
    },
    aplicaAjusteParcelas() {
      this.$store.state.fastCarregando = true;
      let dataAtual = new Date();
      this.promiseGetFastApi("api/fast_usuario_curso_aula_atividade/lista_hora", "").then(obj => {
        if (JSON.parse(obj).dataAtual) {
          dataAtual = new Date(JSON.parse(obj).dataAtual);
          if (this.dataPrimeiraParcela) dataAtual = new Date(this.dataPrimeiraParcela.split("-")[0] + "/" + this.dataPrimeiraParcela.split("-")[1] + "/" + this.dataPrimeiraParcela.split("-")[2])
          // Evita problema de fevereiro
          if (dataAtual.getDate() > 28) { 
            dataAtual.setMonth(dataAtual.getMonth() + 1)
            dataAtual.setDate(1)
          }
          this.fastSteps.step3.plano.parcelas = []
          if (this.fastSteps.step3.plano.tempo_determinado) {
            let n_parcelas = 0.00
            n_parcelas = this.fastSteps.step3.plano.tempo_determinado / this.fastSteps.step3.plano.periocidade_cada_unidade 

            for (let index = 0; index < n_parcelas; index++) {
              if (this.fastSteps.step3.plano.dia_mes == "M") {
                if (index) dataAtual.setMonth( dataAtual.getMonth() + this.fastSteps.step3.plano.periocidade_cada_unidade );
              } else if (this.fastSteps.step3.plano.dia_mes == "D") {
                if (index) dataAtual = this.addDays(dataAtual, this.fastSteps.step3.plano.periocidade_cada_unidade );
              }

              let objParcela = {
                parcela: index + 1,
                total: this.fastSteps.step3.invoice.total,
                valor_parcela: this.removeCasasDecimais(parseFloat(this.fastSteps.step3.invoice.total) / n_parcelas, 2),
                datetime: dataAtual,
                data_formatada: dataAtual.toLocaleString('pt-BR').split(",")[0],
                data_vencimento: dataAtual.toLocaleString('pt-BR').split(",")[0],
                acrescimo: 0.00,
                desconto: 0.00,
                taxa_boleto: 0.00,
                taxa_cartao: 0.00,
                outras_taxas: 0.00,
                nr_parcelas: n_parcelas                  

              }                
             
              this.fastSteps.step3.plano.parcelas.push(objParcela)
            }

            if (this.fastSteps.step3.plano.parcelas.length) {                
              let parcelasSomadas = this.fastSteps.step3.plano.parcelas.length * parseFloat(this.fastSteps.step3.plano.parcelas[0].valor_parcela)               
              console.log("parcelasSomadas", parcelasSomadas)
              if (parseFloat(this.fastSteps.step3.invoice.total) > parseFloat(parcelasSomadas).toFixed(2)) {
                let incremento = parseFloat(this.fastSteps.step3.invoice.total) - parseFloat(parseFloat(parcelasSomadas).toFixed(2))
                this.fastSteps.step3.plano.parcelas[0].valor_parcela = parseFloat(this.fastSteps.step3.plano.parcelas[0].valor_parcela) + parseFloat(incremento.toFixed(2))
              } 
            }
          }
        }
        this.$store.state.fastCarregando = false;
      });
      
    },
    async validaTerceiroPasso() {
      return new Promise(async (resolve, reject) => {
        if (this.fastSteps.step3.plano.id_plano_pagamento && this.fastSteps.step3.tipo.id_tipo_pagamento) {
          this.fastSecretariaTipoPagamento.forEach(t => {
            if (t.id_tipo_pagamento == this.fastSteps.step3.tipo.id_tipo_pagamento) {
              this.fastSteps.step3.tipo.nome_tipo_pagamento = t.nome_tipo_pagamento
              this.fastSteps.step3.tipo.forma_pagamento = t.forma_pagamento
            }
          })

          if (this.fastSteps.step3.comissao.length) {
            console.log("Está usando comissão")
            this.fastSteps.step3.comissao.forEach(d => {
              console.log("comissao", d)
              if (!d.id_pessoa) {
                this.exibeToasty("Pessoa não selecionada no schema de comissão", "error")
                reject(true)
                return false
              }
              if (!d.payee_code_gerencianet) {
                this.exibeToasty("Pessoa selecionada não possui conta Gerencianet registrada", "error")
                reject(true)
                return false
              }
              if (!d.percentual) {
                this.exibeToasty("A comissão está ativada somente com o uso de porcentagem", "error")
                reject(true)
                return false
              }
            })
            this.fastSteps.step3.comissao.forEach(d => {
              d.valor = d.valor_comissao_parcela
            });
          } else {
            console.log("Não está usando comissão", this.fastSteps.step3.comissao)
          }

          if (this.fastSteps.step3.matricula.valor_matricula) {
            if (this.fastSteps.step3.matricula.valor_matricula > 5) {
              //this.getFastPlataformaAlunoResponsavel(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
              resolve(true);
              return true;
            } else {
              this.exibeToasty("O valor da matrícula deve ser superior a R$ 5,00", "error");
              reject(true)
              return false
            }
          } else {
            //this.getFastPlataformaAlunoResponsavel(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
            resolve(true);
            return true;
          }
          
        } else if (this.fastSteps.step3.pular_pagamento) {
          //this.getFastPlataformaAlunoResponsavel(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
          resolve(true);
          return true;
        } else {
          this.exibeToasty("Escolha um plano e um tipo de pagamento", "error");
          reject(true)
          return false
        }          
      });
    },
    // Quarto passo
    defineAlunoResponsavel() {
      if (this.fastSteps.step4.aluno_responsavel) {
        this.fastSteps.step4.id_pessoa_responsavel = this.fastPessoa.id_pessoa
        this.fastSteps.step4.telefone_responsavel = this.fastPessoa.telefone
        this.fastSteps.step4.nome_responsavel = this.fastPessoa.nome_razao + " " + this.fastPessoa.sobrenome_fantasia
        this.fastSteps.step3.invoice.nome_responsavel = this.fastPessoa.nome_razao + " " + this.fastPessoa.sobrenome_fantasia
      }
    },
    async validaQuartoPasso() {
      return new Promise(async (resolve, reject) => {
        let count = 0
        if (!this.fastSteps.step4.aluno_responsavel) {
          this.$store.state.fastPessoasResponsaveis.lista.forEach(r => {
            if (r.responsavel_financeiro) {
              count++
              this.fastSteps.step4.id_pessoa_responsavel = r.id_pessoa_responsavel
              this.fastSteps.step4.nome_responsavel = r.nome_responsavel
              this.fastSteps.step4.telefone_responsavel = r.celular
              this.fastSteps.step3.invoice.nome_responsavel = r.nome_responsavel
            }
          })
          if (!count) {
            this.fastSteps.step4.id_pessoa_responsavel = 0
            this.fastSteps.step4.nome_responsavel = ""
            this.fastSteps.step4.telefone_responsavel = ""
            this.fastSteps.step3.invoice.nome_responsavel = ""
          }
        }
          
          if (count > 1) {
            this.exibeToasty("Defina apenas um responsável financeiro", "error");
            reject(true)
            return false
          }
          else if (this.fastSteps.step4.id_pessoa_responsavel) {
            this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", "id_pessoa=" + this.fastSteps.step4.id_pessoa_responsavel + "&id_plataforma=" + this.$route.params.id_plataforma).then(obj => {  
              console.log("busca_pessoa_secretaria", JSON.parse(obj))     
              let objPessoa = JSON.parse(obj)
              if (objPessoa.id_pessoa) {    
                let erros = []
                  if (!objPessoa.first_name) erros.push("O nome do responsavel não foi cadastrado")
                  if (!objPessoa.last_name) erros.push("O sobrenome do responsavel não foi cadastrado")
                  if (!objPessoa.cpf_cnpj) erros.push("O CPF/CNPJ do aluno responsavel foi cadastrado")
                  if (!objPessoa.logradouro) erros.push("O endereço do responsavel não foi cadastrado")
                  if (!objPessoa.numero) erros.push("O número do endereço do responsavel não foi cadastrado")
                  if (!objPessoa.bairro) erros.push("O bairro do responsavel não foi cadastrado")
                  if (!objPessoa.cep) erros.push("O CEP do responsavel não foi cadastrado")
                  if (!objPessoa.cidade) erros.push("O cidade do responsavel não foi cadastrado")
                  if (!objPessoa.uf) erros.push("O estado do responsavel não foi cadastrado")
                  if (!objPessoa.telefone) erros.push("O telefone do responsavel não foi cadastrado")
                  if (!objPessoa.datanascimento_abertura) erros.push("A data de nascimento do responsavel não foi cadastrada")
                  if (erros.length) {
                    erros.forEach(e => this.exibeToasty(e, "error"))
                    reject(true)
                    return false
                  } else {
                    this.getSecretariaContratosCurso(this.$route.params.id_plataforma, this.fastSteps.step1.curso.id_secretaria_curso)   
                    resolve(true);
                    return true;
                  }                  
              } else {
                this.exibeToasty("Defina um responsável financeiro", "error");
                reject(true)
                return false
              }
            }).catch(e => {
              this.exibeToasty("Erro ao buscar dados do responsável definido", "error")
              reject(true)
              return false
            })                                
            
          } else {
            this.exibeToasty("Defina um responsável financeiro", "error");
            reject(true)
            return false
          }
          
      });
    },
    // Quinto passo
    async gerarMatricula() {     
      // Verificações
      this.statusGeracaoMatricula.iniciou = true;
      this.hidePrevButton();
      this.hideNextButton();

      let errosMatricula = []
      let errosModulos = 0
      let errosUnidades = 0;
      
      // Verificação do curso
      this.statusGeracaoMatricula.definicao_curso.status = "executing"
      this.statusGeracaoMatricula.definicao_curso.message = "Verificando..."
      await new Promise(done => setTimeout(() => done(), 500));  
      if (this.fastSteps.step1.curso.id_secretaria_curso && this.fastSteps.step1.curso.nome_curso) {          
        this.statusGeracaoMatricula.definicao_curso.status = "ok"
        this.statusGeracaoMatricula.definicao_curso.message = this.fastSteps.step1.curso.nome_curso
      } else {
        this.statusGeracaoMatricula.definicao_curso.status = "error"
        this.statusGeracaoMatricula.definicao_curso.message = "Curso não identificado"    
        errosMatricula.push(this.statusGeracaoMatricula.definicao_curso.message)    
      }

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton()        
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação da turma
        this.statusGeracaoMatricula.definicao_turma.status = "executing"
        this.statusGeracaoMatricula.definicao_turma.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500));  
        if (this.fastSteps.step1.turma.id_turma) {          
          this.statusGeracaoMatricula.definicao_turma.status = "ok"
          this.statusGeracaoMatricula.definicao_turma.message = this.fastSteps.step1.turma.sigla_turma + " - " + this.fastSteps.step1.turma.turno
        } else {
          this.statusGeracaoMatricula.definicao_turma.status = "error"
          this.statusGeracaoMatricula.definicao_turma.message = "Turma não identificada"
          errosMatricula.push(this.statusGeracaoMatricula.definicao_turma.message)  
        }
      }
      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação do plano
        this.statusGeracaoMatricula.definicao_plano.status = "executing"
        this.statusGeracaoMatricula.definicao_plano.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500));  
        if (this.fastSteps.step2.plano == "curso") {
          this.statusGeracaoMatricula.definicao_plano.status = "ok"
          this.statusGeracaoMatricula.definicao_plano.message = "Curso completo"
        } else if (this.fastSteps.step2.plano == "modulo") {
          if (this.fastSteps.step2.modulo.lista.length) {
            this.statusGeracaoMatricula.definicao_plano.status = "ok"
            this.statusGeracaoMatricula.definicao_plano.message = this.fastSteps.step2.modulo.lista.length + " módulo(s) escolhido(s)"
          } else {
            this.statusGeracaoMatricula.definicao_plano.status = "error"
            this.statusGeracaoMatricula.definicao_plano.message = "Nenhum módulo foi escolhido"
            errosMatricula.push(this.statusGeracaoMatricula.definicao_plano.message)
          }
        } else if (this.fastSteps.step2.plano == "unidade") {
          if (this.fastSteps.step2.unidade.lista.length) {
            this.statusGeracaoMatricula.definicao_plano.status = "ok"
            this.statusGeracaoMatricula.definicao_plano.message = this.fastSteps.step2.unidade.lista.length + " unidades(s) escolhida(s)"
          } else {
            this.statusGeracaoMatricula.definicao_plano.status = "error"
            this.statusGeracaoMatricula.definicao_plano.message = "Nenhuma unidade curricular foi escolhida"
            errosMatricula.push(this.statusGeracaoMatricula.definicao_plano.message)
          }
        }
      }      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação do plano de pagamento
        this.statusGeracaoMatricula.definicao_plano_pagamento.status = "executing"
        this.statusGeracaoMatricula.definicao_plano_pagamento.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500));  
        if (this.fastSteps.step3.plano.id_plano_pagamento) {          
          this.statusGeracaoMatricula.definicao_plano_pagamento.status = "ok"
          this.statusGeracaoMatricula.definicao_plano_pagamento.message = this.fastSteps.step3.plano.nome_plano
        } else {
          if (this.fastSteps.step3.pular_pagamento) {
            this.statusGeracaoMatricula.definicao_plano_pagamento.status = "ok"
            this.statusGeracaoMatricula.definicao_plano_pagamento.message = "Matrícula sem pagamento"
          } else {
            this.statusGeracaoMatricula.definicao_plano_pagamento.status = "error"
            this.statusGeracaoMatricula.definicao_plano_pagamento.message = "Plano de pagamento não identificada"
            errosMatricula.push(this.statusGeracaoMatricula.definicao_plano_pagamento.message)
          }        
        }
      }
      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação do tipo de pagamento
        this.statusGeracaoMatricula.definicao_tipo_pagamento.status = "executing"
        this.statusGeracaoMatricula.definicao_tipo_pagamento.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500));  
        if (this.fastSteps.step3.tipo.id_tipo_pagamento) {          
          this.statusGeracaoMatricula.definicao_tipo_pagamento.status = "ok"
          this.statusGeracaoMatricula.definicao_tipo_pagamento.message = this.fastSteps.step3.tipo.nome_tipo_pagamento
        } else {
          if (this.fastSteps.step3.pular_pagamento) {
            this.statusGeracaoMatricula.definicao_tipo_pagamento.status = "ok"
            this.statusGeracaoMatricula.definicao_tipo_pagamento.message = "Matrícula sem pagamento"
          } else {
            this.statusGeracaoMatricula.definicao_tipo_pagamento.status = "error"
            this.statusGeracaoMatricula.definicao_tipo_pagamento.message = "Plano de pagamento não identificada"
            errosMatricula.push(this.statusGeracaoMatricula.definicao_tipo_pagamento.message)
          }        
        }
      }      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        this.statusGeracaoMatricula.definicao_responsavel.status = "executing"
        this.statusGeracaoMatricula.definicao_responsavel.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500))        
        if (this.fastSteps.step4.id_pessoa_responsavel && this.fastSteps.step4.nome_responsavel) {
          this.statusGeracaoMatricula.definicao_responsavel.status = "ok"
          this.statusGeracaoMatricula.definicao_responsavel.message = this.fastSteps.step4.nome_responsavel
        } else {
          this.statusGeracaoMatricula.definicao_responsavel.status = "error"
          this.statusGeracaoMatricula.definicao_responsavel.message = "Responsável financeiro não identificado"
          errosMatricula.push(this.statusGeracaoMatricula.definicao_responsavel.message)
        }
      }      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação e geração da matrícula
        this.statusGeracaoMatricula.matricula_gerada.status = "executing"
        this.statusGeracaoMatricula.matricula_gerada.message = "Verificando..."

        await new Promise(done => setTimeout(() => done(), 500)); 

        this.fastSteps.step3.matricula.tipo_matricula = this.fastSteps.step1.curso.nivel.trim() ? this.fastSteps.step1.curso.nivel.trim() : errosMatricula.push("O nível do curso não foi identificado")
        this.fastSteps.step3.matricula.id_pessoa_aluno = this.fastPessoa.id_pessoa ? this.fastPessoa.id_pessoa : errosMatricula.push("O id_pessoa do aluno não foi identificado")
        this.fastSteps.step3.matricula.RA =  this.fastSteps.step3.matricula.RA ? this.fastSteps.step3.matricula.RA : null

        if (this.fastSteps.step3.matricula.valor_matricula) {
          if (this.fastSteps.step3.matricula.valor_matricula < 5.00) {
            errosMatricula.push("O valor da matrícula não pode ser inferior a R$ 5,00")
          }
          else {
            this.statusGeracaoMatricula.matricula_gerada.message = "Valor da matrícula: R$ " + this.formataPreco(this.fastSteps.step3.matricula.valor_matricula) + " ..."
            await new Promise(done => setTimeout(() => done(), 500))
          }            
        } else {
          this.fastSteps.step3.matricula.valor_matricula = 0          
          this.statusGeracaoMatricula.matricula_gerada.message = "Taxa de matrícula gratuita..."
          await new Promise(done => setTimeout(() => done(), 500))
        }

        if (this.fastSteps.step3.matricula.ativo) {
          this.fastSteps.step3.matricula.ativo = true
        } else {
          this.fastSteps.step3.matricula.ativo = false
        }

        this.fastSteps.step3.matricula.vencimento_matricula = this.fastSteps.step3.matricula.vencimento_matricula ? this.fastSteps.step3.matricula.vencimento_matricula : null
        this.fastSteps.step3.matricula.desconto_matricula = this.fastSteps.step3.matricula.desconto_matricula ? this.fastSteps.step3.matricula.desconto_matricula : 0
        this.fastSteps.step3.matricula.outros_valores = this.fastSteps.step3.matricula.outros_valores ? this.fastSteps.step3.matricula.outros_valores : 0
        this.fastSteps.step3.matricula.curso_definido = true
        this.fastSteps.step3.matricula.plano_pagto_definido = true
        this.fastSteps.step3.matricula.ajuste_definido = true
        this.fastSteps.step3.matricula.responsavel_definido = true
        this.fastSteps.step3.matricula.contrato_gerado = false
        this.fastSteps.step3.matricula.boleto_gerado = false
        this.fastSteps.step3.matricula.id_plataforma = this.$route.params.id_plataforma
        this.fastSteps.step3.matricula.id_turma = this.fastSteps.step1.turma.id_turma
        this.fastSteps.step3.matricula.id_invoice = this.fastSteps.step1.turma.id_invoice ? this.fastSteps.step1.turma.id_invoice : 0
        this.fastSteps.step3.matricula.ano_matricula = new Date().getFullYear().toString()
        this.fastSteps.step3.matricula.pago = false
      }

      if (errosMatricula.length) {
        this.statusGeracaoMatricula.matricula_gerada.status = "error"
        this.statusGeracaoMatricula.matricula_gerada.message = errosMatricula.join(", ")
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        if (this.fastSteps.step2.plano == "curso") {
          this.fastSteps.step3.matricula.matricula_por_programa = false
          this.fastSteps.step3.matricula.matricula_por_unidade = false
        } else if (this.fastSteps.step2.plano == "modulo") {
          this.fastSteps.step3.matricula.matricula_por_programa = true
          this.fastSteps.step3.matricula.matricula_por_unidade = false
        } else if (this.fastSteps.step2.plano == "unidade") {
          this.fastSteps.step3.matricula.matricula_por_programa = false
          this.fastSteps.step3.matricula.matricula_por_unidade = true
        } 
      }  

      this.statusGeracaoMatricula.matricula_gerada.message = "Buscando dados do responsável..."

      let objPessoaResponsavel = {}
      this.getFastHoraServidor()
      .then(() => {
        return this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", "id_pessoa=" + this.fastSteps.step4.id_pessoa_responsavel + "&id_plataforma=" + this.$route.params.id_plataforma)
      })
      .then(obj => {     
          let erros = []  
          objPessoaResponsavel = JSON.parse(obj)
          if (objPessoaResponsavel.id_pessoa) {    
            objPessoaResponsavel.id_plataforma = this.$route.params.id_plataforma

            // Validações da pessoa resposnável.
            if (!objPessoaResponsavel.nome_razao) erros.push(`O responsável não possui nome cadastrado`)    

            if (!objPessoaResponsavel.cpf_cnpj) erros.push(`O responsável não possui CPF/CNPJ cadastrado`)            
            if (objPessoaResponsavel.cpf_cnpj) {
              if (!this.validaCpfCnpj(objPessoaResponsavel.cpf_cnpj)) erros.push("CPF/CNPJ inválido")
            }    

            if (!objPessoaResponsavel.cep) erros.push(`O responsável não possui endereço completo cadastrado`)
            if (objPessoaResponsavel.cep) {
              if (!/^(?:\d{8}|\d{5}|\d{9}|\d{7})$/.test(objPessoaResponsavel.cep.replace("-", ""))) erros.push("CEP inválido")
            }  
            
            if (!objPessoaResponsavel.datanascimento_abertura) erros.push(`O responsável não possui a data de nascimento cadastrada`)
            if (objPessoaResponsavel.datanascimento_abertura) {
              let objDataNasc = new Date(`${objPessoaResponsavel.datanascimento_abertura}T00:00:00`)
              let cincoAnosAtras = new Date(this.$store.state.fastDataAtualServidor.setFullYear(this.$store.state.fastDataAtualServidor.getFullYear() - 5))
              if (objDataNasc > cincoAnosAtras) erros.push("A data de nascimento deve ser superior a 5 anos atrás")
            }

            if (!objPessoaResponsavel.email) erros.push(`O responsável não possui email cadastrado`)

            if (!objPessoaResponsavel.telefone) erros.push(`O responsável não possui telefone cadastrado`)
            // if (!this.validaTelefoneFormatado(objPessoaResponsavel.telefone)) erros.push("O telefone está no formato incorreto")

            if (objPessoaResponsavel.numero) objPessoaResponsavel.numero = objPessoaResponsavel.numero.trim()  
           
          } else {
            erros.push("Erro ao retornar dados do usuário")
          }
          return erros
      })
      .then(erros => {
        if (erros.length) {
          this.statusGeracaoMatricula.matricula_gerada.status = "error"
          this.statusGeracaoMatricula.matricula_gerada.message = erros.join(", ")
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.showPrevButton() 
          this.showNextButton()
          this.statusGeracaoMatricula.iniciou = false
        } else {
          
            // Outras validações
          this.fastSteps.step3.invoice.id_pessoa_responsavel = this.fastSteps.step4.id_pessoa_responsavel
          this.fastSteps.step3.invoice.cpf_responsavel = objPessoaResponsavel.cpf_cnpj
          this.fastSteps.step3.invoice.cep_responsavel = objPessoaResponsavel.cep
          this.fastSteps.step3.invoice.endereco_responsavel_numero = objPessoaResponsavel.numero.trim()
          this.fastSteps.step3.invoice.endereco_responsavel_complemento = objPessoaResponsavel.complemento
          this.fastSteps.step3.invoice.ativo = true
          this.fastSteps.step3.invoice.cpf_aluno = this.fastPessoa.cpf_cnpj   

          this.fastSteps.step3.invoice.id_plano_pagamento = this.fastSteps.step3.plano.id_plano_pagamento
          this.fastSteps.step3.invoice.id_plataforma = this.$route.params.id_plataforma
          this.fastSteps.step3.invoice.nome_aluno = this.fastPessoa.nome_razao + " " + this.fastPessoa.sobrenome_fantasia
          this.fastSteps.step3.invoice.obs = null

          this.fastSteps.step3.invoice.id_pessoa_aluno = this.fastPessoa.id_pessoa

          

          let obj = {
            fast_secretaria_matricula_aluno: this.fastSteps.step3.matricula,
            fast_tesouraria_invoice: this.fastSteps.step3.invoice,
            fast_tesouraria_invoice_parcela: [],
            fast_pessoa: objPessoaResponsavel,
            fast_usuario: {
              email: objPessoaResponsavel.email
            },
            fast_pessoa_telefone: {
              telefone: objPessoaResponsavel.telefone
            },
            fast_secretaria_curso: {
              nome_curso: this.fastSteps.step1.curso.nome_curso ? this.fastSteps.step1.curso.nome_curso : "Curso"
            }            
          }

          if (this.fastSteps.step3.comissao.length) {
            obj.fast_tesouraria_invoice_schema_comissao = this.fastSteps.step3.comissao 
          }

          console.log("Objeto matrícula", obj)

          if (!this.fastSteps.step3.plano.parcelas.length && !this.fastSteps.step3.pular_pagamento) erros.push(`O número de parcelas não foi identificado`)          

          this.fastSteps.step3.plano.parcelas.forEach((p, index) => {   
            // Validação de parcelas
            if (p.valor_parcela) {
              if (p.valor_parcela < 5.00) erros.push(`O valor da parcela ${index + 1} é inferior a R$ 5,00`)
            } else {
              erros.push(`Valor da parcela ${index + 1} não identificado`)
            }
            if (p.total) {
              if (p.total < 5.00) erros.push(`O valor total é inferior a R$ 5,00`)
            } else {
              erros.push(`Valor total não foi identificado`)
            }

            let dataAtual = new Date(this.$store.state.fastDataAtualServidor)
            if (p.data_vencimento) {
              let objDataVenc = new Date(`${p.data_vencimento}T00:00:00`)                  
              if (objDataVenc < dataAtual) erros.push(`A data de vencimento da parcela ${index + 1} está no passado`)
            }

            if (!p.acrescimo) p.acrescimo = 0
            if (!p.desconto) p.desconto = 0
            if (!p.taxa_boleto) p.taxa_boleto = 0
            if (!p.taxa_cartao) p.taxa_cartao = 0
            if (!p.outras_taxas) p.outras_taxas = 0

            if (!this.fastSteps.step3.tipo.id_tipo_pagamento) erros.push(`O tipo de pagamento na parcela não foi identificado`)

            let fast_tesouraria_invoice_parcela = {
              id_invoice: 0,
              parcela: index + 1,
              valor_parcela: p.valor_parcela,
              acrescimo: p.acrescimo,
              desconto: p.desconto,
              total: p.total,
              valor_pago: 0.00,
              taxa_boleto: p.taxa_boleto,
              taxa_cartao: p.taxa_cartao,
              outras_taxas: p.outras_taxas,
              data_vencimento: p.data_vencimento.split("/")[2] + "-" + p.data_vencimento.split("/")[1] + "-" + p.data_vencimento.split("/")[0],
              //data_vencimento: p.data_vencimento.split("/")[1] + "/" + p.data_vencimento.split("/")[0] + "/" + p.data_vencimento.split("/")[2],
              path_boleto: null,
              id_gateway: 0,
              autenticacao: null,
              pago: false,
              log_pagto_auditoria: null,
              id_tipo_pagamento: this.fastSteps.step3.tipo.id_tipo_pagamento,
              nr_parcelas: this.fastSteps.step3.plano.parcelas.length
            }
            obj.fast_tesouraria_invoice_parcela.push(fast_tesouraria_invoice_parcela)

        });
        
          if (erros.length) {
            this.statusGeracaoMatricula.matricula_gerada.status = "error"
            this.statusGeracaoMatricula.matricula_gerada.message = erros.join(", ")
            erros.forEach(e => this.exibeToasty(e, "error"))
            this.showPrevButton() 
            this.showNextButton() 
            this.statusGeracaoMatricula.iniciou = false
          } else {
            
            // Matrícula sem invoice
            if (this.fastSteps.step3.pular_pagamento) {
              
              this.promisePostFastApi(obj.fast_secretaria_matricula_aluno, "api/fast_secretaria_matricula_aluno/insere")
              .then(res => {                
                if (res.length){
                  const json = res[0]                  
                  if (json.id_matricula) {
                    this.fastSteps.step3.matricula.id_matricula = json.id_matricula
                    this.fastSteps.step3.matricula.id_invoice = json.id_invoice_matricula
                    this.fastSteps.step3.invoice.id_matricula = json.id_matricula                    
                    this.statusGeracaoMatricula.matricula_gerada.status = "ok"
                    this.statusGeracaoMatricula.matricula_gerada.message = "Matrícula #" + this.fastSteps.step3.matricula.id_matricula + " gerada"

                    // Se for módulo
                    if (this.fastSteps.step2.plano == "modulo") {
                    this.statusGeracaoMatricula.insercao_modulos.status = "executing"
                    this.statusGeracaoMatricula.insercao_modulos.message = "Cadastrando..."

                    // Gravar em [fastead].[fast_secretaria_matricula_aluno_programa]
                    
                    this.fastSteps.step2.modulo.lista.forEach((m, index) => {            
                        this.fastSecretariaModulosTotal.forEach(t => {
                          if (m == t.id_secretaria_curso_programa) {
                            let objMatriculaAlunoPrograma = {
                              id_plataforma: this.$route.params.id_plataforma,
                              fast_secretaria_matricula_aluno_programa: {            
                                id_matricula: this.fastSteps.step3.matricula.id_matricula,
                                id_programa_turma: t.id_programa_turma
                              }
                            }                  
                            console.log("objMatriculaAlunoPrograma", objMatriculaAlunoPrograma)
                            this.insereMatriculaAlunoPrograma(objMatriculaAlunoPrograma).then(res => {
                              if ((index + 1) == this.fastSteps.step2.modulo.lista.length && !errosModulos) {
                                this.statusGeracaoMatricula.insercao_modulos.status = "ok"
                                this.statusGeracaoMatricula.insercao_modulos.message = this.fastSteps.step2.modulo.lista.length + " módulo(s) cadastrados(s)"
                              }                 
                            }).catch(() => {
                              errosModulos++
                              this.statusGeracaoMatricula.insercao_modulos.status = "error"
                              this.statusGeracaoMatricula.insercao_modulos.message = "Matrícula gerada mas ocorreu um erro ao cadastrar os módulos. Informe o id_matricula #" + this.fastSteps.step3.matricula.id_matricula + " ao administrador do sistema."
                              this.showPrevButton() 
                              this.showNextButton()
                            })
                          }
                        })
                    })
                    
                    } else if (this.fastSteps.step2.plano == "unidade") {
                      this.statusGeracaoMatricula.insercao_unidades.status = "executing"
                      this.statusGeracaoMatricula.insercao_unidades.message = "Cadastrando..."

                      // Gravar em [fastead].[fast_secretaria_matricula_aluno_unidade]
                      this.fastSteps.step2.unidade.lista.forEach((m, index) => {            
                          this.fastSecretariaUnidadesTotal.forEach(t => {
                            if (m == t.id_secretaria_curso_programa_unidade) {
                              let objMatriculaAlunoUnidade = {
                                id_plataforma: this.$route.params.id_plataforma,
                                fast_secretaria_matricula_aluno_unidade: {            
                                  id_matricula: this.fastSteps.step3.matricula.id_matricula,
                                  id_programa_turma_unidade: t.id_programa_turma_unidade
                                }
                              }                  
                              console.log("objMatriculaAlunoUnidade", objMatriculaAlunoUnidade)
                              this.insereMatriculaAlunoUnidade(objMatriculaAlunoUnidade).then(res => {
                                if ((index + 1) == this.fastSteps.step2.unidade.lista.length && !errosUnidades) {
                                  this.statusGeracaoMatricula.insercao_unidades.status = "ok"
                                  this.statusGeracaoMatricula.insercao_unidades.message = this.fastSteps.step2.unidade.lista.length + " unidades(s) cadastradas(s)"
                                }                 
                              }).catch(() => {
                                errosUnidades++
                                this.statusGeracaoMatricula.insercao_modulos.status = "error"
                                this.statusGeracaoMatricula.insercao_modulos.message = "Matrícula gerada mas ocorreu um erro ao cadastrar as unidades curriculares. Informe o id_matricula #" + this.fastSteps.step3.matricula.id_matricula + " ao administrador do sistema."
                                this.showPrevButton() 
                                this.showNextButton()
                              })
                            }
                          })
                      })          
                    }
                    
                    this.statusGeracaoMatricula.iniciou = false
                    this.showNextButton()

                  } else {    
                    this.showModal('modalErrosRequisicao')
                    this.showPrevButton() 
                    this.showNextButton()
                    this.statusGeracaoMatricula.matricula_gerada.status = "error"
                    this.statusGeracaoMatricula.matricula_gerada.message = "Erro ao gerar matrícula. Consulte um administrador do sistema."
                  }
                }        
              })
              .catch(e => {
                this.exibeToasty(e, "error")
                this.statusGeracaoMatricula.matricula_gerada.status = "error"
                this.statusGeracaoMatricula.matricula_gerada.message = "Erro ao gerar matrícula. Consulte um administrador do sistema."
                this.showPrevButton() 
                this.showNextButton() 
              })
              
            } else {    
            // Matrícula com invoice    
                  
              this.promisePostFastApi(obj, "api/fast_secretaria_matricula_aluno/insere_matricula")
              .then(res => {                
                if (res.length){
                  const json = JSON.parse(res)
                  
                  if (json.status) {
                    this.fastSteps.step3.matricula.id_matricula = json.id_matricula
                    this.fastSteps.step3.matricula.id_invoice = json.id_invoice_matricula
                    this.fastSteps.step3.invoice.id_matricula = json.id_matricula                    
                    this.statusGeracaoMatricula.matricula_gerada.status = "ok"
                    this.statusGeracaoMatricula.matricula_gerada.message = "Matrícula #" + this.fastSteps.step3.matricula.id_matricula + " gerada"

                    this.fastSteps.step3.invoice.id_invoice = json.id_invoice_curso
                    this.statusGeracaoMatricula.insercao_invoice.status = "ok"
                    this.statusGeracaoMatricula.insercao_invoice.message = "Invoice #" + this.fastSteps.step3.invoice.id_invoice + " gerado"

                    // Se for módulo
                    if (this.fastSteps.step2.plano == "modulo") {
                    this.statusGeracaoMatricula.insercao_modulos.status = "executing"
                    this.statusGeracaoMatricula.insercao_modulos.message = "Cadastrando..."

                    // Gravar em [fastead].[fast_secretaria_matricula_aluno_programa]
                    this.fastSteps.step2.modulo.lista.forEach((m, index) => {            
                        this.fastSecretariaModulosTotal.forEach(t => {
                          if (m == t.id_secretaria_curso_programa) {
                            let objMatriculaAlunoPrograma = {
                              id_plataforma: this.$route.params.id_plataforma,
                              fast_secretaria_matricula_aluno_programa: {            
                                id_matricula: this.fastSteps.step3.matricula.id_matricula,
                                id_programa_turma: t.id_programa_turma
                              }
                            }                  
                            console.log("objMatriculaAlunoPrograma", objMatriculaAlunoPrograma)
                            this.insereMatriculaAlunoPrograma(objMatriculaAlunoPrograma).then(res => {
                              if ((index + 1) == this.fastSteps.step2.modulo.lista.length && !errosModulos) {
                                this.statusGeracaoMatricula.insercao_modulos.status = "ok"
                                this.statusGeracaoMatricula.insercao_modulos.message = this.fastSteps.step2.modulo.lista.length + " módulo(s) cadastrados(s)"
                              }                 
                            }).catch(() => {
                              errosModulos++
                              this.statusGeracaoMatricula.insercao_modulos.status = "error"
                              this.statusGeracaoMatricula.insercao_modulos.message = "Matrícula gerada mas ocorreu um erro ao cadastrar os módulos. Informe o id_matricula #" + this.fastSteps.step3.matricula.id_matricula + " ao administrador do sistema."
                              this.showPrevButton() 
                              this.showNextButton()
                            })
                          }
                        })
                    })
                    
                    } else if (this.fastSteps.step2.plano == "unidade") {
                      this.statusGeracaoMatricula.insercao_unidades.status = "executing"
                      this.statusGeracaoMatricula.insercao_unidades.message = "Cadastrando..."

                      // Gravar em [fastead].[fast_secretaria_matricula_aluno_unidade]
                      
                      this.fastSteps.step2.unidade.lista.forEach((m, index) => {            
                          this.fastSecretariaUnidadesTotal.forEach(t => {
                            if (m == t.id_secretaria_curso_programa_unidade) {
                              let objMatriculaAlunoUnidade = {
                                id_plataforma: this.$route.params.id_plataforma,
                                fast_secretaria_matricula_aluno_unidade: {            
                                  id_matricula: this.fastSteps.step3.matricula.id_matricula,
                                  id_programa_turma_unidade: t.id_programa_turma_unidade
                                }
                              }                  
                              console.log("objMatriculaAlunoUnidade", objMatriculaAlunoUnidade)
                              this.insereMatriculaAlunoUnidade(objMatriculaAlunoUnidade).then(res => {
                                if ((index + 1) == this.fastSteps.step2.unidade.lista.length && !errosUnidades) {
                                  this.statusGeracaoMatricula.insercao_unidades.status = "ok"
                                  this.statusGeracaoMatricula.insercao_unidades.message = this.fastSteps.step2.unidade.lista.length + " unidades(s) cadastradas(s)"
                                }                 
                              }).catch(() => {
                                errosUnidades++
                                this.statusGeracaoMatricula.insercao_modulos.status = "error"
                                this.statusGeracaoMatricula.insercao_modulos.message = "Matrícula gerada mas ocorreu um erro ao cadastrar as unidades curriculares. Informe o id_matricula #" + this.fastSteps.step3.matricula.id_matricula + " ao administrador do sistema."
                                this.showPrevButton() 
                                this.showNextButton()                                
                              })
                            }
                          })
                      })          
                    }

                    if (!errosModulos && !errosUnidades) {
                      this.statusGeracaoMatricula.insercao_parcelas.status = "ok"
                      this.statusGeracaoMatricula.insercao_parcelas.message = this.fastSteps.step3.plano.parcelas.length + " parcelas(s) cadastradas(s)"                        
                    } 
                    
                    this.statusGeracaoMatricula.iniciou = false
                    this.showNextButton()

                  } else {
                    if (json.description.split(",").length) {
                      this.fastErros = json.description.split(",")
                    } else {
                      this.fastErros.push(json.description)
                      console.log("this.fastErros", this.fastErros)
                    }            
                    this.showModal('modalErrosRequisicao')
                    this.showPrevButton() 
                    this.showNextButton()
                    this.statusGeracaoMatricula.iniciou = false
                    this.statusGeracaoMatricula.matricula_gerada.status = "error"
                    this.statusGeracaoMatricula.matricula_gerada.message = "Erro ao gerar matrícula. Consulte um administrador do sistema."

                  }
                }        
              })
              .catch(e => {
                this.exibeToasty(e, "error")
                this.statusGeracaoMatricula.matricula_gerada.status = "error"
                this.statusGeracaoMatricula.matricula_gerada.message = "Erro ao gerar matrícula. Consulte um administrador do sistema."
                this.showPrevButton() 
                this.showNextButton() 
                this.statusGeracaoMatricula.iniciou = false
              })    
                      
            }
          }       
        }
      })
      .catch(e => {
        this.statusGeracaoMatricula.matricula_gerada.status = "error"
        this.statusGeracaoMatricula.matricula_gerada.message = e
        this.exibeToasty(e, "error")
        this.showPrevButton() 
        this.showNextButton() 
      })

        
    },
    async insereMatriculaAluno(obj) {
      return new Promise(async (resolve, reject) => {    
        this.promiseInserirFastApi(obj, "fast_secretaria_matricula_aluno").then(res =>  {     
          console.log("insereMatriculaAluno", res)     
          if (res.length) {
              this.exibeToasty("Matrícula gerada com sucesso", "success");
              resolve(res)
              return true
            } else {
              this.exibeToasty("Erro ao gerar matrícula", "error");
              reject(false)
              return false;
            }               
          }).catch(e => {
            this.exibeToasty("Erro ao gerar matrícula", "error");            
            reject(false)
            return false;
          })
      });
    },
    async insereMatriculaAlunoPrograma(obj) {
      return new Promise(async (resolve, reject) => {        
        this.promiseInserirFastApi(obj, "fast_secretaria_matricula_aluno_programa").then(res =>  {     
        console.log("insereMatriculaAlunoPrograma", res)     
        if (res.length) {
            this.exibeToasty("Módulo cadastrado com sucesso", "success");
            resolve(res)
            return true
          } else {
            this.exibeToasty("Erro ao cadastrar módulo", "error");
            reject(false)
            return false;
          }               
        }).catch(e => {
          this.exibeToasty("Erro ao cadastrar módulo", "error");            
          reject(false)
          return false;
        })       
      });
    },
    async insereMatriculaAlunoUnidade(obj) {
      return new Promise(async (resolve, reject) => {        
        this.promiseInserirFastApi(obj, "fast_secretaria_matricula_aluno_unidade").then(res =>  {     
        console.log("insereMatriculaAlunoProgramaUnidade", res)     
        if (res.length) {
            this.exibeToasty("Unidade curricular cadastrado com sucesso", "success");
            resolve(res)
            return true
          } else {
            this.exibeToasty("Erro ao cadastrar unidade curricular", "error");
            reject(false)
            return false;
          }               
        }).catch(e => {
          this.exibeToasty("Erro ao cadastrar unidade curricular", "error");            
          reject(false)
          return false;
        })       
      });
    },
    async insereTesourariaInvoice(obj) {
      return new Promise(async (resolve, reject) => {        
        this.promiseInserirFastApi(obj, "fast_tesouraria_invoice").then(res =>  {     
          console.log("insereTesourariaInvoice", res)     
          if (res.length) {
              this.exibeToasty("Invoice gerado com sucesso", "success");
              resolve(res)
              return true
            } else {
              this.exibeToasty("Erro ao cadastrar invoice", "error");
              reject(false)
              return false;
            }               
          }).catch(e => {
            this.exibeToasty("Erro ao cadastrar invoice", "error");            
            reject(false)
            return false;
          })       
      });
    },
    async insereTesourariaInvoiceParcela(obj) {
      return new Promise(async (resolve, reject) => {        
        this.promiseInserirFastApi(obj, "fast_tesouraria_invoice_parcela").then(res =>  {     
          console.log("insereTesourariaInvoiceParcela", res)     
          if (res.length) {
              //this.exibeToasty("Parcelas geradas com sucesso", "success");
              resolve(res)
              return true
            } else {
              //this.exibeToasty("Erro ao cadastrar parcelas", "error");
              reject(false)
              return false;
            }               
          }).catch(e => {
            //this.exibeToasty("Erro ao cadastrar parcelas", "error");            
            reject(false)
            return false;
          })          
      });
    },    
    getSecretariaContratosCurso(id_plataforma, id_secretaria_curso){
      if (!this.fastSecretariaContratosCurso.length) {
        this.fastSteps.step5.loading = true;
        this.promiseGetFastApi("api/fast_secretaria_contrato/lista_curso", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=" + id_secretaria_curso).then(obj => {
          console.log("getSecretariaContratos", obj);
          if (obj.length) {
            this.fastSecretariaContratosCurso = obj;
            this.fastContratoEscolhido.id_contrato = obj[0].id_contrato
          }
          this.fastSteps.step5.loading = false;
        }).catch(e => {
          console.log(e);
        })
      }      
    },
    geraPdfContrato() {   
      console.log("this.fastContratoEscolhido", this.fastContratoEscolhido)
      if (!this.fastContratoEscolhido.id_contrato) {
        this.$store.state.fastCarregando = false;
        this.exibeToasty("Selecione um template de contrato", "error");
      } else {
        this.$store.state.fastCarregando = true;
        this.fastSecretariaContratosCurso.forEach(c => {
          if (c.id_contrato == this.fastContratoEscolhido.id_contrato) {
            this.fastContratoEscolhido.html_template = c.html_template;
          }
        });   
        
        if (!this.fastContratoEscolhido.html_template) this.exibeToasty("Erro ao buscar template de contrato", "error");
        else {
          let contrato = this.fastContratoEscolhido.html_template;
          let erros = [];

          if (this.fastContratoEscolhido.html_template.indexOf("%nome_aluno%") != "-1" && !this.fastPessoa.first_name) erros.push("O nome do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%nome_aluno%") != "-1" && !this.fastPessoa.last_name) erros.push("O sobrenome do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%cpf_aluno%") != "-1" && !this.fastPessoa.cpf_cnpj) erros.push("O CPF/CNPJ do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%logradouro_aluno%") != "-1" && !this.fastPessoa.logradouro) erros.push("O endereço do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%numero_aluno%") != "-1" && !this.fastPessoa.numero) erros.push("O número do endereço do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%bairro_aluno%") != "-1" && !this.fastPessoa.bairro) erros.push("O bairro do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%cep_aluno%") != "-1" && !this.fastPessoa.cep) erros.push("O CEP do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%cidade_aluno%") != "-1" && !this.fastPessoa.cidade) erros.push("O cidade do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%uf_aluno%") != "-1" && !this.fastPessoa.uf) erros.push("O estado do aluno não foi cadastrado")

          if (erros.length) {
            this.$store.state.fastCarregando = false;
            erros.forEach(e => this.exibeToasty(e, "error"))
          } else {
            this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", "id_pessoa=" + this.fastSteps.step4.id_pessoa_responsavel + "&id_plataforma=" + this.$route.params.id_plataforma).then(obj => {       
              let objPessoa = JSON.parse(obj)
              if (objPessoa.id_pessoa) {    
                  if (this.fastContratoEscolhido.html_template.indexOf("%nome_responsavel%") != "-1" && !objPessoa.first_name) erros.push("O nome do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%nome_responsavel%") != "-1" && !objPessoa.last_name) erros.push("O sobrenome do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%cpf_responsavel%") != "-1" && !objPessoa.cpf_cnpj) erros.push("O CPF/CNPJ do aluno responsavel foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%logradouro_responsavel%") != "-1" && !objPessoa.logradouro) erros.push("O endereço do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%numero_responsavel%") != "-1" && !objPessoa.numero) erros.push("O número do endereço do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%bairro_responsavel%") != "-1" && !objPessoa.bairro) erros.push("O bairro do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%cep_responsavel%") != "-1" && !objPessoa.cep) erros.push("O CEP do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%cidade_responsavel%") != "-1" && !objPessoa.cidade) erros.push("O cidade do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%uf_responsavel%") != "-1" && !objPessoa.uf) erros.push("O estado do responsavel não foi cadastrado")

                  if (!erros.length) {
                    contrato = contrato.replaceAll(
                    "%nome_aluno%",
                      this.fastPessoa.first_name.trim() + " " + this.fastPessoa.last_name.trim()
                    );
                    contrato = contrato.replaceAll(
                    "%nome_responsavel%",
                      objPessoa.first_name.trim() + " " + objPessoa.last_name.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%cpf_aluno%",
                      this.fastPessoa.cpf_cnpj.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%cpf_responsavel%",
                      objPessoa.cpf_cnpj.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%logradouro_aluno%",
                      this.fastPessoa.logradouro.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%logradouro_responsavel%",
                      objPessoa.logradouro.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%numero_aluno%",
                      this.fastPessoa.numero.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%numero_responsavel%",
                      objPessoa.numero.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%bairro_aluno%",
                      this.fastPessoa.bairro.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%bairro_responsavel%",
                      objPessoa.bairro.trim() 
                    );                
                    contrato = contrato.replaceAll(
                      "%cep_aluno%",
                      this.fastPessoa.cep.trim().replaceAll("-","")
                    );                    
                    contrato = contrato.replaceAll(
                      "%cep_responsavel%",
                      objPessoa.cep.trim().replaceAll("-","")
                    );
                    contrato = contrato.replaceAll(
                      "%cidade_aluno%",
                      this.fastPessoa.cidade.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%cidade_responsavel%",
                      objPessoa.cidade.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%uf_aluno%",
                      this.fastPessoa.uf.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%uf_responsavel%",
                      objPessoa.uf.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%complemento_aluno%",
                      this.fastPessoa.complemento.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%complemento_responsavel%",
                      objPessoa.complemento.trim()
                    );

                    console.log("this.fastSteps", this.fastSteps)

                    // Valores
                    let valor_total = 0
                    valor_total += this.fastSteps.step3.matricula.valor_matricula ? parseFloat(this.fastSteps.step3.matricula.valor_matricula) : 0
                    valor_total += this.fastSteps.step3.invoice.total ? this.fastSteps.step3.invoice.total : 0

                    //https://github.com/portujs/extenso.js
                    let extenso = require('extenso')

                    console.log("valor_total", valor_total)

                    // Valor total = matricula + curso
                    valor_total = valor_total.toFixed(2)
                    let valor_total_extenso = extenso(valor_total.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_total%", `R$ ${this.formataPreco(valor_total)} (${valor_total_extenso})`);

                    // Valor da matrícula
                    let valor_matricula = this.fastSteps.step3.matricula.valor_matricula ? parseFloat(this.fastSteps.step3.matricula.valor_matricula) : 0
                    valor_matricula = valor_matricula.toFixed(2)
                    let valor_matricula_extenso = extenso(valor_matricula.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_matricula%", `R$ ${this.formataPreco(valor_matricula)} (${valor_matricula_extenso})`);

                    // Valor total do curso = soma todas as parcelas
                    let valor_total_curso = 0;
                    if (this.fastSteps.step3.plano.parcelas.length) {
                      valor_total_curso = this.fastSteps.step3.plano.parcelas.map(o=>o.valor_parcela).reduce((a,c)=>a+c);
                    }
                    valor_total_curso = valor_total_curso.toFixed(2)
                    let valor_total_curso_extenso = extenso(valor_total_curso.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_total_curso%", `R$ ${this.formataPreco(valor_total_curso)} (${valor_total_curso_extenso})`);

                    // Número de parcelas
                    let numero_parcelas = this.fastSteps.step3.plano.parcelas.length;
                    contrato = contrato.replaceAll("%numero_parcelas%", `${numero_parcelas}`);

                    // Valor da parcela
                    let valor_parcela = 0
                    if (this.fastSteps.step3.plano.parcelas.length) {
                      valor_parcela = this.fastSteps.step3.plano.parcelas[this.fastTesourariaInvoice.parcelas.length - 1].valor_parcela                      
                    }
                    valor_parcela = valor_parcela.toFixed(2)
                    let valor_parcela_extenso = extenso(valor_parcela.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_parcela%", `R$ ${this.formataPreco(valor_parcela)} (${valor_parcela_extenso})`);

                    // Dia da parcela
                    let dia_parcela = ""
                    if (this.fastSteps.step3.plano.parcelas.length) {
                      if (this.fastSteps.step3.plano.parcelas[0].data_vencimento) dia_parcela = this.fastSteps.step3.plano.parcelas[0].data_vencimento.split("T")[0].split("-")[2]
                    }
                    contrato = contrato.replaceAll(
                      "%dia_parcela%",
                      dia_parcela.trim()
                    );

                    // Gera pdf
                    this.fastContratoEscolhido.html_template = contrato;
                    this.$refs.html2Pdf.generatePdf();

                    this.$store.state.fastCarregando = false;
                  } else {                    
                    erros.forEach(e => this.exibeToasty(e, "error"))
                    this.$store.state.fastCarregando = false;
                  }  
              } else {
                this.exibeToasty("Erro ao buscar dados do responsável", "error");
                this.$store.state.fastCarregando = false;
              }
            }).catch(e => {
              console.log(e);
              this.exibeToasty("Erro ao buscar dados do responsável", "error");
              this.$store.state.fastCarregando = false;
            })
          }                  
        }
      }      
    },  
    // beforeDownload -> não utilizado mais
    async beforeDownload ({ html2pdf, options, pdfContent }) {
        await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()
            console.log("pdfContent", pdfContent)
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i)
                pdf.setFontSize(10)
                pdf.setTextColor(150)
                pdf.text('Página ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
            } 
        }).save()
    },    
    async atualizaMatriculaAluno(obj) {
      return new Promise(async (resolve, reject) => {    
        this.promiseAtualizarFastApi(obj, "fast_secretaria_matricula_aluno").then(res =>  {     
          console.log("insereMatriculaAluno", res)     
          if (res.length) {
              this.exibeToasty("Matrícula atualizada com sucesso", "success");
              resolve(res)
              return true
            } else {
              this.exibeToasty("Erro ao atualizar matrícula", "error");
              reject(false)
              return false;
            }               
          }).catch(e => {
            this.exibeToasty("Erro ao atualizar matrícula", "error");            
            reject(false)
            return false;
          })
      });
    },  
    fileValidation(e) {
      return false;
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastSteps.step5.path_contrato = result.url;
        this.fastSteps.step3.matricula.path_contrato = result.url;        
      }
    },  
    fileSuccessEmail(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        let fast_secretaria_contrato_aluno = {
          id_usuario_logado: this.fastPessoa.Usuario_cadastro,
          id_plataforma: this.$route.params.id_plataforma,
          email_recuperacao: this.fastPessoa.Email,
          path_contrato: result.url
        }

        console.log("fast_secretaria_contrato_aluno", fast_secretaria_contrato_aluno)

        this.$store.state.fastCarregando = true;
        // Controller fast_secretaria_contrato
        this.promiseInserirFastApi(fast_secretaria_contrato_aluno, "fast_secretaria_contrato_aluno").then((res) => {
          if (res == "Email enviado") {
            this.exibeToasty("Email enviado com sucesso", "success");
            this.hideModal("modalEnviarContratoEmail")
          } else {
            this.exibeToasty(res, "error");
          }
          this.$store.state.fastCarregando = false;
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          this.$store.state.fastCarregando = false;
        });
      }
    }, 
    retornaTotalMatricula(valor_matricula, desconto_matricula, outros_valores){
      let valor_total = 0
      if (valor_matricula) {
        valor_total = parseFloat(valor_matricula)
        if (desconto_matricula) valor_total -= parseFloat(desconto_matricula)
        if (outros_valores) valor_total += parseFloat(outros_valores)
      }
      return this.formataPreco(valor_total)
    },
    async validaQuintoPasso() {
      return new Promise(async (resolve, reject) => {
        // Não é mais obrigatório o envio previo do contrato
        //if (this.fastSteps.step5.path_contrato && this.fastSteps.step3.invoice.id_matricula) {   
        if (this.fastSteps.step3.invoice.id_matricula) { 

          this.$store.state.fastCarregando = true;
          this.fastSteps.step3.matricula.contrato_gerado = true
          this.atualizaMatriculaAluno(this.fastSteps.step3.matricula).then((res) => {
            this.fastSteps.step3.invoice.parcelas = []
            this.getTesourariaInvoice(this.fastSteps.step3.invoice.id_matricula);

            res.forEach(m => {
              if (m.id_matricula == this.fastSteps.step3.matricula.id_matricula) {
                console.log("Matrícula", m)
                this.fastSteps.step3.matricula = m
                if (m.id_invoice) {
                  this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista", "id_invoice=" + m.id_invoice).then(res => {        
                    console.log("getTesourariaInvoiceParcelasTaxaMatricula", res)
                    if (res.length) {     
                      this.fastSteps.step6.matricula.matriculaInvoiceParcela = res;              
                    } else {
                      this.exibeToasty("Nenhuma parcela para a taxa de matrícula", "error");
                    }      
                    this.fastSteps.step6.matricula.loading = false  
                  }).catch(e => {
                    console.log(e);
                    this.exibeToasty("Erro ao recuperar parcelas", "error");
                    this.fastSteps.step6.matricula.loading = false 
                  })
                }
              }
            })

            this.$store.state.fastCarregando = false;
            
            //this.getSecretariaMatriculaAluno(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
            this.hidePrevButton();
            resolve(true);
            return true;
          }).catch(e => {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Erro ao atualizar matrícula", "error");
            reject(true)
            return false
          })   
        } else {
          this.exibeToasty("Gere a matrícula e faça upload do contrato assinado", "error");
          reject(true)
          return false
        }          
      });
    },
    // Sexto passo
    getTesourariaInvoice(id_matricula){
      
      //if (!this.fastSteps.step3.invoice.parcelas.length) {
        this.promiseGetFastApi("api/fast_tesouraria_invoice/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_matricula=" + id_matricula).then(json => {        
          console.log("getTesourariaInvoice", json)
          if (json.length) {          
            json.forEach(e => {
              if (e.id_invoice == this.fastSteps.step3.invoice.id_invoice) {
                e.parcelas = []          
                this.fastSteps.step3.invoice = e;
              }
            })  
            console.log("this.fastSteps.step3.invoice.id_invoice", this.fastSteps.step3.invoice.id_invoice)
            this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista", "id_invoice=" + this.fastSteps.step3.invoice.id_invoice).then(json2 => {        
              console.log("getTesourariaInvoiceParcelas", json2)
              if (json2.length) {     
                this.fastSteps.step6.invoice.parcelas = json2;              
              } else {
                this.exibeToasty("Nenhuma parcela para esse invoice", "error");
              }      
              this.fastSteps.step6.invoice.loading = false  
            }).catch(e => {
              console.log(e);
              this.exibeToasty("Erro ao recuperar parcelas", "error");
              this.fastSteps.step6.invoice.loading = false 
            })
          } else {
            this.exibeToasty("Nenhum registro invoice para essa matrícula", "error");
            this.fastSteps.step6.invoice.loading = false 
          }        
        }).catch(e => {
          console.log(e);
          this.exibeToasty("Erro ao recuperar invoice", "error");
          this.fastSteps.step6.invoice.loading = false 
        })
      //}      
    },
    getSecretariaMatriculaAluno(id_plataforma, id_pessoa_aluno){
      if (!this.fastSteps.step3.matricula.id_matricula) {
        this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista", "id_plataforma=" + id_plataforma + "&id_programa=0&id_pessoa_aluno=" + id_pessoa_aluno).then(obj => {
          console.log("getSecretariaMatriculaAluno", obj);
          if (obj.length) {
            obj.forEach(m => {
              if (m.id_matricula == this.fastSteps.step3.matricula.id_matricula) {
                console.log("Matrícula", m)
                this.fastSteps.step3.matricula = m
              }
            })
            
          }
          else {

          }
        }).catch(e => {
          console.log(e);
        })
      }      
    },
    async validaSextoPasso() {
      return new Promise(async (resolve, reject) => {
          resolve(true);
          return true;
      });
    },
    exibeModalWhatsApp(boleto){
      this.fastLinkWhats.path_boleto = boleto.path_boleto
      this.fastLinkWhats.parcela = boleto.parcela
      this.fastLinkWhats.valor_parcela_total = boleto.valor_parcela_total
      this.fastLinkWhats.data_vencimento = boleto.data_vencimento
      this.fastLinkWhats.nome_plataforma = boleto.nome_plataforma
      this.fastLinkWhats.tipo = boleto.tipo
      this.fastLinkWhats.telefone_responsavel = this.fastSteps.step4.telefone_responsavel
      this.fastLinkWhats.telefone_aluno = this.fastPessoa.telefone

      this.showModal('modalWhatsApp')
    },
    retornaLinkWhats(telefone, path_boleto, parcela, valor_parcela_total, data_vencimento, nome_plataforma, tipo){
      let link = ""
      let tel = telefone
      if (tel) {
        tel = telefone.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")
        tel = `55${tel}`
        if (path_boleto) {
          let text = path_boleto
          if (parcela && valor_parcela_total && data_vencimento && nome_plataforma && tipo) {
            let referente = ""
            if (tipo == "I") {
              referente = "à cobrança"
            } else if (tipo == "M"){
              referente = "à taxa de matrícula"
            } else if (tipo == "C"){
              referente = "ao curso definido"
            } else {
              referente = "à cobrança"
            }
            text = `Olá, segue a parcela ${parcela} do carnê no valor de R$ ${this.formataPreco(valor_parcela_total)}. Cobrança referente ${referente}, emitida por ${nome_plataforma}, com vencimento para ${this.formataDataT(data_vencimento)}. Acesse o boleto pelo link: ${path_boleto}`
          }
          link = `https://api.whatsapp.com/send?phone=${tel}&text=${text}`

        } else {
          //this.exibeToasty("Boleto não cadastrado", "error")
        }
      } else {
        //this.exibeToasty("Telefone não registrado", "error")
      }
      return link
    },
    async onComplete() {
      return new Promise(async (resolve, reject) => {  
        window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/secretaria/aluno/" + this.fastPessoa.id_pessoa
      });
    },    
    handleValidation: function (isValid, tabIndex) {
      console.log("Tab: " + tabIndex + " valid: " + isValid);
    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg;
    },
    hidePrevButton() {
      document.querySelectorAll(".wizard-footer-left")[0].classList.add("d-none");
    },
    showPrevButton() {
      document.querySelectorAll(".wizard-footer-left")[0].classList.remove("d-none");
    },
    hideNextButton() {
      document.querySelectorAll(".wizard-footer-right")[0].classList.add("d-none");
    },
    showNextButton() {
      document.querySelectorAll(".wizard-footer-right")[0].classList.remove("d-none");
    },
    // Comissao
    async getComissaoSchemas(id_plataforma){   
      this.fastComissaoSchemaDeterminacoes.total = [] 
      this.fastComissaoSchemaDeterminacoes.loading = false

      this.fastComissaoSchema.total = []
      this.fastComissaoSchema.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_comissao_schema/lista", "id_plataforma=" + id_plataforma).then(res => {
        if (res.length) {
          this.fastComissaoSchema.total = res.filter(e => !e.desativado)
        } else {
          this.fastComissaoSchema.total = []
        }
        this.fastComissaoSchema.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastComissaoSchema.loading = false
      })
    },
    async getGatewaysAssinatura(){
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_assinatura_gateway/lista_disponiveis", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastGatewaysAssinatura = res
          console.log("gateways", this.fastGatewaysAssinatura)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    async getComissaoSchemaDeterminacoes(e){     
      let id_comissao_schema = e.target.value

      if (id_comissao_schema > 0) {
        this.fastComissaoSchemaDeterminacoes.total = [] 
        this.fastSteps.step3.comissao = []
        this.fastComissaoSchemaDeterminacoes.loading = true
        this.promiseGetFastApi("api/fast_tesouraria_comissao_schema_determinacao/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_comissao_schema=" + id_comissao_schema)
        .then(res => {
          if (res.length) {
            let valida_curso = true
            res.forEach(obj => {
              if (obj.id_secretaria_curso) {
                if (obj.id_secretaria_curso != this.fastSteps.step1.curso.id_secretaria_curso) {
                  valida_curso = false
                }
              }
              obj.pessoas = []
              
              obj.valor_comissao_parcela = 0
              obj.valor_comissao_total = 0
              if (this.fastSteps.step3.invoice.parcelas.length) {
                let valor_parcela = this.fastSteps.step3.invoice.parcelas[0].valor_parcela
                if (obj.percentual) {
                  obj.valor_comissao_parcela = (valor_parcela * obj.percentual) / 100
                  obj.valor_comissao_total = this.fastSteps.step3.invoice.parcelas.length * obj.valor_comissao_parcela
                } else if (obj.valor) {
                  obj.valor_comissao_parcela = obj.valor                  
                  obj.valor_comissao_total = this.fastSteps.step3.invoice.parcelas.length * obj.valor_comissao_parcela
                }
              }
              
            })

            if (valida_curso) {
              this.fastComissaoSchemaDeterminacoes.total = res

              this.fastComissaoSchemaDeterminacoes.total.forEach(obj => {
                this.fastSteps.step3.comissao.push(Object.assign({}, obj))
                if (obj.id_funcao_pessoa) {
                  this.getPessoasPorFuncao(obj)
                }
              })
              console.log("this.fastSteps.step3.comissao", this.fastSteps.step3.comissao)
            } else {
              this.exibeToasty("Schema de comissão não aplicável a este curso", "error")
            }
            
          }
          this.fastComissaoSchemaDeterminacoes.loading = false
        }).catch(e => {
          console.log(e)
          this.exibeToasty(e, "error")
          this.fastComissaoSchemaDeterminacoes.loading = false
        })
      } else {
        this.fastComissaoSchemaDeterminacoes.total = [] 
        this.fastSteps.step3.comissao = []
        this.fastComissaoSchemaDeterminacoes.loading = false
      }
      
    },
    async getPessoasPorFuncao(obj){    
      this.promiseGetFastApi("api/fast_pessoa_funcao/lista_funcao", "id_plataforma=" + this.$route.params.id_plataforma + "&id_funcao_pessoa=" + obj.id_funcao_pessoa)
      .then(res => {        
        if (res.length) {
          obj.pessoas = res
        } 
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
      })
    },
    definePessoaComissao(e){
      let id_pessoa = e.target.value
      let id_determinacao = e.target.id
      
      if (this.fastSteps.step3.comissao.length) {
        this.fastSteps.step3.comissao.forEach(d => {                    
          if (d.id_determinacao == id_determinacao) {
            d.id_pessoa = id_pessoa  
            
            this.fastComissaoSchemaDeterminacoes.total.forEach(e => {
              if (e.id_determinacao == id_determinacao && e.pessoas.length) {
                console.log("Determinação encontrada, listando pessoas")
                e.pessoas.forEach(p => {
                  if (p.id_pessoa == id_pessoa) {
                    console.log("Pessoa encontrada")
                    d.payee_code_gerencianet = p.payee_code_gerencianet
                  }
                })
              }
            })
          }
        })
      }
      
    },
    async insereComissaoProfessor(){
      let erros = []
      if (!this.fastProfessorComissao.comissao_porcent) erros.push("O valor deve ser superior a 0")
      if (!this.fastProfessorComissao.id_professor) erros.push("Escolha um professor")
      this.fastProfessorComissao.id_invoice = this.fastSteps.step3.invoice.id_invoice
      this.fastProfessorComissao.id_plataforma = this.$route.params.id_plataforma
      console.log("this.fastProfessorComissao", this.fastProfessorComissao)
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastProfessorComissao.loading = true
      this.promisePostFastApi(this.fastProfessorComissao, "api/fast_plataforma_professor_comissao/insere")
        .then((res) => {
          console.log("api/fast_plataforma_professor_comissao/insere", res)
          this.exibeToasty("Comissão aplicada com sucesso", "success")
          this.fastProfessorComissao.loading = false
        })
        .catch((e) => {
          this.fastProfessorComissao.loading = false
          this.exibeToasty(e, "error")
        });
      }
      
    }
  },
};
</script>

<style scope>
.fast-pdf-html-template *{
  margin: 0 !important;
}
.class-pointer {
     cursor: pointer;
   }

   .blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Ajuste para gerador de pdf */
.vue-html2pdf p {
  margin: 0 !important;
  padding: 0 !important;  
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}

@media (max-width: 992px) {
   
  .wizard-nav-pills li {
    flex-basis: 100% !important;
  }
  .wizard-progress-with-circle, .wizard-nav-pills, .wizard-header {
    display: none !important;
  }
}

</style>
